import _ from 'lodash';

const watchPosition = (successCallback, errorCallback) => (
  navigator.geolocation.watchPosition(
    _.throttle((position) => {
      const { latitude: lat, longitude: lng } = position.coords;

      successCallback({ lat, lng });
    }, 250),
    (error) => {
      if (error.code === 1) {
        errorCallback(error);
      }
    },
    {
      timeout: 10000,
      enableHighAccuracy: true,
      maximumAge: 10000,
    },
  )
);

const clearWatch = (id) => (
  navigator.geolocation.clearWatch(id)
);

const permissions = async () => {
  const { state } = await navigator.permissions.query({ name: 'geolocation' });

  return state;
};

export default {
  watchPosition,
  clearWatch,
  permissions,
};
