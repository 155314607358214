<template>
  <div id="configuracoes" class="center">
    <form @submit.prevent="submit">
      <div class="form-field">
        <label for="geolocation">Localização</label>
        <div class="geolocation-field">
          <input id="geolocation" type="text" :value="geolocation.address" :disabled="isLoading" maxlength="100" @click="openGeolocationModal">
          <button type="button" @click="clearGeolocation" v-if="geolocation.lat && geolocation.lng && !isLoading">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/>
            </svg>
          </button>
        </div>
        <span class="error">ATENÇÃO! Esta opção sobrescreve a localização atual do GPS</span>
      </div>
      <div class="form-field">
        <label for="payment-type">Forma de pagamento preferencial</label>
        <div class="select" :class="{ 'disabled': isLoading }">
          <select id="payment-type" v-model="paymentType" :disabled="isLoading">
            <option v-for="paymentType in paymentTypes" :value="paymentType.value" :key="paymentType.value">
              {{ paymentType.value | formatPaymentType }}
            </option>
          </select>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
          </svg>
        </div>
      </div>
      <div class="form-field">
        <label for="service-type">Tipo de serviço preferencial</label>
        <div class="select" :class="{ 'disabled': isLoading }">
          <select id="service-type" v-model="serviceType" :disabled="isLoading">
            <option v-for="serviceType in serviceTypes" :value="serviceType.value" :key="serviceType.value">
              {{ serviceType.value | formatServiceType }}
            </option>
          </select>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
          </svg>
        </div>
      </div>
      <ajax-button class="button button-primary" type="submit" :is-disabled="isLoading">Salvar</ajax-button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import constants from '../constants';
import geolocation from '../geolocation';
import garupa from '../api/garupa';
import GeolocationModal from '../components/GeolocationModal.vue';
import AjaxButton from '../components/AjaxButton.vue';

const { PaymentTypes, ServiceTypes } = constants;

export default {
  components: { AjaxButton },
  data() {
    return {
      geolocation: {},
      paymentTypes: [],
      serviceTypes: [],
      paymentType: null,
      serviceType: null,
      isLoading: false,
    };
  },
  computed: mapState(['settings', 'geolocationWatchId', 'userData']),
  methods: {
    async submit() {
      this.isLoading = true;

      const { error, result } = await garupa.salvarConfiguracao(
        this.geolocation.lat,
        this.geolocation.lng,
        this.geolocation.address,
        this.paymentType,
        this.serviceType,
      );

      this.isLoading = false;

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        geolocation.clearWatch(this.geolocationWatchId);
        if (!this.geolocation.lat && !this.geolocation.lng) {
          const id = geolocation.watchPosition(
            (location) => (this.$store.commit('setCurrentPosition', location)),
            () => (this.$store.commit('setShowGeolocationMessage', true)),
          );

          this.$store.commit('setGeolocationWatchId', id);
        } else {
          this.$store.commit('setGeolocationWatchId', null);
          this.$store.commit('setCurrentPosition', { lat: this.geolocation.lat, lng: this.geolocation.lng });
        }

        this.$store.commit('setSettings', result);
        this.$store.commit('showSuccessAlert', 'Configurações salvas com sucesso');
        localStorage.setItem('settings', JSON.stringify(result));
      }
    },
    openGeolocationModal() {
      this.$modal.show(GeolocationModal, {
        lat: this.geolocation.lat,
        lng: this.geolocation.lng,
      }, {
        width: '95%',
        height: '95%',
      }, {
        'before-close': this.geolocationModalClose,
      });
    },
    clearGeolocation() {
      this.geolocation = {};
    },
    geolocationModalClose(event) {
      if (event.params) {
        this.geolocation = {
          lat: event.params.lat,
          lng: event.params.lng,
          address: event.params.address ? event.params.address : 'Endereço não encontrado',
        };
      }
    },
    setPaymentTypes() {
      const pagamento = this.validaPagamentoTipoUsuario();
      this.paymentTypes = _.map(pagamento, (value) => ({
        value,
      }));
    },
    setServiceTypes() {
      this.serviceTypes = _.map(ServiceTypes, (value) => ({
        value,
      }));
    },
    validaPagamentoTipoUsuario() {
      if (this.userData.tipo === 4) { // se o usuário for do tipo empresa
        return { POS_CORPORATIVO: 5 };
      }
      if (this.userData.tipo === 3 && !this.userData.empresa) { // se for facilitador e não estiver vinculado à alguma empresa
        return { DINHEIRO: 4 }; // se não estiver vinculado à nenhuma empresa
      }
      return PaymentTypes;
    },
  },
  async created() {
    if (this.settings) {
      const {
        lat_localizacao_atual: lat,
        lng_localizacao_atual: lng,
        endereco_localizacao_atual: address,
        tipo_pagamento_preferencial: paymentType,
        tipo_servico_preferencial: serviceType,
      } = this.settings;

      this.geolocation = { lat, lng, address };
      this.paymentType = paymentType;
      this.serviceType = serviceType;

      this.setPaymentTypes();
      this.setServiceTypes();
    }
  },
};
</script>

<style scoped lang="scss">
  #configuracoes {
    form {
      display: flex;
      flex-flow: column;

      .geolocation-field {
        display: flex;

        button {
          margin: 0;
          padding: 0;
          outline: 0;
          border: none;
          background-color: transparent;
          color: #db4d6c;
          cursor: pointer;

          svg {
            display: block;
            width: 40px;
          }
        };
      }

      .button {
        width: 100%;
        max-width: 360px;
        margin: auto;
      }
    }
  }
</style>
