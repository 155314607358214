<template>
  <div id="leaflet-map">
    <div id="mapContainer" ref="mapContainer"></div>
    <div id="map" ref="map"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { GeoFire } from 'geofire';
import L from 'leaflet';
import firebase from '../api/firebase';
import mapStyles from '../utils/maps';

const { createLeafletMap, setCircleLocation } = mapStyles;

export default {
  computed: mapState(['map', 'markers', 'currentPosition']),
  data() {
    return {
      geoQuery: null,
      Map: {},
    };
  },
  watch: {
    currentPosition(newVal) {
      const { lat, lng } = newVal;
      this.markers[0] = this.setCircleLocation(this.markers[0]);

      this.geoQuery.updateCriteria({
        center: [lat, lng],
        radius: 2,
      });
    },
    currentPositionMarker(newVal) {
      console.log(newVal); // TODO handle
    },
    broadcastMarker(newVal) {
      console.log(newVal); // TODO handle
    },

  },
  methods: {
    calculateBearing(lat1, lon1, lat2, lon2) {
      const radLat1 = this.toRadians(lat1);
      const radLon1 = this.toRadians(lon1);
      const radLat2 = this.toRadians(lat2);
      const radLon2 = this.toRadians(lon2);

      const deltaLon = radLon2 - radLon1;
      const y = Math.sin(deltaLon) * Math.cos(radLat2);
      const x = Math.cos(radLat1) * Math.sin(radLat2) - Math.sin(radLat1) * Math.cos(radLat2) * Math.cos(deltaLon);
      const bearing = this.toDegrees(Math.atan2(y, x));

      return (bearing + 360) % 360;
    },

    toRadians(degrees) {
      return (parseFloat(degrees) * Math.PI) / 180;
    },

    toDegrees(radians) {
      return (radians * 180) / Math.PI;
    },
    setupLeafletMap() {
      const map = createLeafletMap(this.$refs.mapContainer, this.currentPosition);
      this.$store.commit('setMap', map);
    },
    setCircleLocation(marker = null) {
      return setCircleLocation(this.map, this.currentPosition, marker);
    },
    setupObservers() {
      const myZoom = {
        start: this.map.getZoom(),
        end: this.map.getZoom(),
      };
      this.map.on('zoomend', () => {
        this.$store.commit('setMapCenter', { lat: this.map.getCenter().lat, lng: this.map.getCenter().lng });
        myZoom.end = this.map.getZoom();
        const diff = myZoom.start - myZoom.end;
        for (let stepZoom = 0; stepZoom !== diff;) {
          if (diff > 0) {
            this.markers[0].setRadius(this.markers[0].getRadius() * 2);
            stepZoom += 1;
          } else if (diff < 0) {
            this.markers[0].setRadius(this.markers[0].getRadius() / 2);
            stepZoom -= 1;
          }
        }
        myZoom.start = this.map.getZoom();
      });

      this.map.on('dragend', () => {
        this.$store.commit('setMapCenter', { lat: this.map.getCenter().lat, lng: this.map.getCenter().lng });
      });
    },
    setupGeoQueries() {
      const drivers = {};
      const databaseRef = firebase.geo.database().ref();
      const geoFire = new GeoFire(databaseRef.child('motoGeo'));
      this.geoQuery = geoFire.query({
        center: [this.currentPosition.lat, this.currentPosition.lng],
        radius: 2,
      });
      this.geoQuery.on('key_entered', (key, location) => {
        const carIcon = L.icon({
          iconUrl: require('@/assets/svg/carGreen.svg'),
          iconSize: [20, 40],
        });
        const marker = L.marker([location[0], location[1]], {
          icon: carIcon,
        }).addTo(this.map);

        drivers[key] = { lat: location[0], lng: location[1], marker };
      });

      this.geoQuery.on('key_exited', (key) => {
        this.map.removeLayer(drivers[key].marker);
      });

      this.geoQuery.on('key_moved', (key, location) => {
        drivers[key].marker.setLatLng(
          L.latLng(location[0], location[1]),
        );
        const angle = this.calculateBearing(drivers[key].lat, drivers[key].lng, location[0], location[1]);
        drivers[key].marker.setRotationOrigin('center');
        // eslint-disable-next-line radix
        drivers[key].marker.setRotationAngle(parseInt(angle));
        [drivers[key].lat, drivers[key].lng] = location;
      });
    },
  },
  mounted() {
    this.setupLeafletMap();
    this.setupGeoQueries();
    this.setupObservers();
    const currentPositionMarker = this.setCircleLocation();

    this.$store.commit('setMarkers', [currentPositionMarker]);
  },
};
</script>

<style scoped lang="scss">
  #leaflet-map {
    width: 100%;
    height: 100%;
    display: flex;
  }
  #mapContainer {
    width: 100vw;
    height: 100%;
    z-index: 10;
  }
</style>
