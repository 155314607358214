import L from 'leaflet';

const CircleStart = {
  color: '#FFF',
  fillColor: '#db4d6c',
  fillOpacity: 1,
  radius: 28,
  weight: 5,
  opacity: 0.7,
};

const CircleEnd = {
  radius: 1,
  fillColor: '#EA1E63',
  fillOpacity: 1,
  color: '#201E1E',
  strokeColor: '#201E1E',
  strokeWeight: 1,
};

const createLeafletMap = (el, currentPosition) => {
  const map = L.map(el, { zoomControl: false }).setView([currentPosition.lat, currentPosition.lng], 16);
  L.tileLayer(
    'http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}',
    {
      maxZoom: 22,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
  ).addTo(map);
  return map;
};

const setCircleLocation = (map, currentPosition, marker = null) => {
  if (marker) {
    map.removeLayer(marker);
  }
  return L.circle([currentPosition.lat, currentPosition.lng], CircleStart).addTo(map);
};

export default {
  setCircleLocation,
  createLeafletMap,
  CircleStart,
  CircleEnd,
};
