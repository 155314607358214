<template>
  <div id="confirmation-modal">
    <span class="title">{{ title }}</span>
    <div class="buttons">
      <button class="cancel button" @click="cancel">Não</button>
      <button class="confirm button button-primary" @click="confirm">Sim</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    confirm() {
      this.$emit('close', true);
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  #confirmation-modal {
    padding: 25px;
    background-color: #eee;
    color: #333;

    .title {
      text-align: center;
      display: block;
      font-size: 18px;
      line-height: 22px;
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        border-radius: 2px;
      }

      .cancel {
        color: #333;
        flex-grow: 1;
      }

      .confirm {
        flex-grow: 1;
        margin-left: 10px;
      }
    }
  }
</style>
