<template>
  <div id="aprovar-faturas" class="center">
    <loader v-if="isLoading"></loader>
    <table v-else>
      <thead>
        <tr>
          <th>#</th>
          <th>Valor Total</th>
          <th>Valor Pago</th>
          <th>Número NF</th>
          <th>Início</th>
          <th>Fim</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!invoices.length">
          <td colspan="8" class="text-center">Nenhum registro encontrado</td>
        </tr>
        <tr v-for="(invoice, index) in invoices" :key="invoice.uid">
          <td class="number">{{ index + 1 }}</td>
          <td>{{ invoice.valor_total | formatMoney }}</td>
          <td>{{ invoice.valor_pago | formatMoney }}</td>
          <td>{{ invoice.numero_nota_fiscal || '-' }}</td>
          <td>{{ invoice.data_inicio | formatDate }}</td>
          <td>{{ invoice.data_fim | formatDate }}</td>
          <td class="status" :class="{ 'approved': invoice.status === 2, 'pending': invoice.status === 1, 'paid': invoice.status === 3 }">
            {{ invoice.status | formatInvoiceStatus }}
          </td>
          <td class="actions" width="90">
            <dropdown-menu right="0" width="150px">
              <template v-slot:button>
                <div class="button button-primary">
                  <span>Ações</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
                  </svg>
                </div>
              </template>
              <template v-slot:menu>
                <ul>
                  <li @click="changeStatus(invoice, 2)">
                    <button v-if="invoice.status != 3" :disabled="invoice.status === 2">Aprovar</button>
                  </li>
                  <li>
                  <button @click="exportar(invoice.uid)">Download</button>
                  </li>
                </ul>
              </template>
            </dropdown-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import garupa from '../api/garupa';
import Loader from '../components/Loader.vue';
import ConfirmationModal from '../components/ConfirmationModal.vue';
import DropdownMenu from '../components/DropdownMenu.vue';

export default {
  components: { Loader, DropdownMenu },
  data() {
    return {
      isLoading: false,
      invoices: [],
    };
  },
  computed: mapState(['userData']),
  methods: {
    async changeStatus(invoice, status) {
      this.$modal.show(ConfirmationModal, {
        title: 'Deseja aprovar a fatura selecionada?',
      }, {
        width: '350px',
        height: 'auto',
      }, {
        'before-close': async (event) => {
          if (event.params) {
            this.isLoading = true;

            const { error } = await garupa.alterarStatusFatura(invoice.uid, status);

            this.isLoading = false;

            if (error) {
              this.$store.commit('showErrorAlert', error);
            } else {
              this.$store.commit('showSuccessAlert', 'Status da fatura alterado com sucesso');
              await this.faturas();
            }
          }
        },
      });
    },
    async faturas() {
      this.isLoading = true;

      const { error, result } = await garupa.faturas();

      this.isLoading = false;

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        this.invoices = result || [];
      }
    },
    async exportar(uid) {
      try {
        const url = process.env.VUE_APP_SIGG_API_URL;
        const payload = { uidFatura: uid };
        this.$store.commit('showWarningAlert', 'Aguarde a geração do arquivo...');
        await axios.post(`${url}exporta-fatura-empresa`, payload, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_SIGG_AUTH}`,
          },
        })
          .then((response) => {
            this.$store.commit('showSuccessAlert', 'Arquivo pronto para download');
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', 'fatura');
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            this.$store.commit('showErrorAlert', error);
          });
      } catch (err) {
        this.$store.commit('showErrorAlert', 'Erro ao gerar arquivo');
      }
    },
  },
  async created() {
    await this.faturas();
  },
};
</script>

<style scoped lang="scss">
#aprovar-faturas {
    height: 100%;
    width: 980px;
  }

  table {
    .text-center {
      text-align: center;
    }

    .actions,
    .number {
      word-break: normal;
    }

    .status {
      &.approved {
        color: #25a58c;
      }

      &.pending {
        color: #f3bd07;
      }

      &.paid {
        color: #145b4d;
      }
    }

    .actions {
      float: right;

      .button {
        display: flex;
        border-radius: 5px;
        min-height: 35px;
        padding-right: 8px;

        span {
          display: block;
          align-self: center;
          font-weight: normal;
          line-height: 20px;
          margin-right: 5px;
        }

        svg {
          width: 18px;
          display: block;
          align-self: center;
        }
      }

      li {
        cursor: default !important;
        padding: 0 !important;
        line-height: normal !important;

        button {
          margin: 0;
          padding: 8px 22px;
          background-color: transparent;
          outline: 0;
          border: none;
          cursor: pointer;
          font-family: inherit;
          line-height: 20px;
          font-size: inherit;
          width: 100%;
          height: 100%;
          text-align: left;
          display: block;
        }
      }
    }
  }
</style>
