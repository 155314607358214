<template>
  <div id="item-selector">
    <button type="button" class="arrow arrow-left" :class="{ 'hidden': selectedIndex === 0 }" @click="slide(-1)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><polyline points="328 112 184 256 328 400" style="fill:none;stroke:#333;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px"/></svg>
    </button>
    <div class="content">
      <span class="title">{{ title }}</span>
      <span class="value">
        <slot></slot>
      </span>
    </div>
    <button type="button" class="arrow arrow-right" :class="{ 'hidden': selectedIndex + 1 >= items.length }" @click="slide(1)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><polyline points="184 112 328 256 184 400" style="fill:none;stroke:#333;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px"/></svg>
    </button>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    items: Array,
    title: String,
    item: Object,
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  methods: {
    slide(increment) {
      this.selectedIndex += increment;
      this.$emit('selected-item', this.items[this.selectedIndex]);
    },
  },
  created() {
    if (!_.isEmpty(this.item)) {
      this.selectedIndex = _.findIndex(this.items, (item) => (
        item.type === this.item.type
      ));
    }
  },
};
</script>

<style scoped lang="scss">
  #item-selector {
    display: flex;
    justify-content: space-between;
    text-align: center;
    box-sizing: border-box;
    padding: 5px;

    .arrow {
      &.hidden {
        visibility: hidden;
      }
    }

    .title {
      display: block;
      line-height: 20px;
      color: #25a58c;
    }

    .value {
      display: block;
      line-height: 45px;
      height: 45px;
      color: #000;
    }

    button {
      background-color: transparent;
      margin: 0;
      padding: 0 8px;
      outline: 0;
      border: none;
      box-sizing: content-box;
      cursor: pointer;

      svg {
        display: block;
        width: 32px
      }
    }

    .content {

    }
  }
</style>
