import Vue from 'vue';
import { format } from 'fecha';
import constants from './constants';

const { TripStatus, ServiceTypes, PaymentTypes, UserTypes, UserStatus } = constants;

Vue.filter('formatServiceType', (value) => {
  switch (value) {
    case ServiceTypes.CLASSICO:
      return 'Clássico';
    case ServiceTypes.EXECUTIVO:
      return 'Executivo';
    case ServiceTypes.PET:
      return 'Pet';
    case ServiceTypes.OBJETO:
      return 'Objeto';
    case ServiceTypes.ESTUDANTE:
      return 'Estudante/Saúde';
    case ServiceTypes.SERVICO_ESPECIALIZADO:
      return 'Serviço Especializado';
    case ServiceTypes.MOTO:
      return 'Moto';
    case ServiceTypes.CARRO_DELIVERY:
      return 'Carro Delivery';
    case ServiceTypes.TAXI:
      return 'Táxi';
    case ServiceTypes.FRETE:
      return 'Frete';
    case ServiceTypes.CLASSICO_KIDS:
      return 'Clássico Kids';
    case ServiceTypes.GARUPA_SEGURO:
      return 'Garupa Seguro';
    default:
      return '-';
  }
});

Vue.filter('formatPaymentType', (value) => {
  switch (value) {
    case PaymentTypes.DINHEIRO:
      return 'Dinheiro';
    case PaymentTypes.POS_CORPORATIVO:
      return 'Pós Pago Garupa';
    default:
      return '-';
  }
});

Vue.filter('formatUserType', (value) => {
  switch (value) {
    case UserTypes.FACILITADOR:
      return 'Facilitador';
    case UserTypes.EMPRESA:
      return 'Empresa';
    case UserTypes.PASSAGEIRO:
      return 'Passageiro';
    default:
      return '-';
  }
});

Vue.filter('formatUserStatus', (value) => {
  switch (value) {
    case UserStatus.ATIVO:
      return 'Ativo';
    case UserStatus.INATIVO:
      return 'Inativo';
    default:
      return '-';
  }
});

Vue.filter('formatInvoiceStatus', (value) => {
  switch (value) {
    case 1:
      return 'Em Análise';
    case 2:
      return 'Aprovada';
    case 3:
      return 'Paga';
    case 4:
      return 'Cancelada';
    default:
      return '-';
  }
});

Vue.filter('formatPhoneNumber', (value) => {
  if (typeof value === 'string' && value.trim().length === 11) {
    return `(${value.substr(0, 2)}) ${value.substr(2, 1)} ${value.substr(3, 4)}-${value.substr(7)}`;
  }

  return value;
});

Vue.filter('formatTripStatus', (value) => {
  switch (value) {
    case TripStatus.SOLICITANDO:
      return 'Solicitando';
    case TripStatus.ACEITANDO_CORRIDA:
      return 'Aceitando Corrida';
    case TripStatus.MOTORISTA_DESLOCAMENTO:
      return 'Motorista Deslocamento';
    case TripStatus.ANDAMENTO:
      return 'Andamento';
    case TripStatus.FINALIZADA:
      return 'Finalizada';
    case TripStatus.CANCELADA_MOTORISTA:
      return 'Cancelada Motorista';
    case TripStatus.CANCELADA_PASSAGEIRO:
      return 'Cancelada Passageiro';
    case TripStatus.SEM_MOTORISTA:
      return 'Sem Motorista';
    case TripStatus.SOLICITACAO_EXPIRADA:
      return 'Solicitação Expirada';
    case TripStatus.CANCELADA_GARUPA:
      return 'Cancelada Garupa';
    default:
      return '-';
  }
});

Vue.filter('formatPrice', (value) => {
  const numberValue = Number(value);

  if (numberValue === 0) {
    return 'Grátis';
  }

  if (numberValue > 0) {
    return `R$ ${numberValue.toFixed(2)}`;
  }

  return '-';
});

Vue.filter('formatMoney', (value) => {
  if (typeof value === 'undefined' || value === null) {
    return '-';
  }

  return `R$ ${value}`;
});

Vue.filter('formatDate', (value) => (
  format(new Date(`${value} 12:00:00`), 'DD/MM/YYYY')
));

Vue.filter('formatDateTime', (value) => (
  format(new Date(value), 'DD/MM/YYYY [ás] HH:mm')
));

Vue.filter('formatTimestamp', (value) => {
  if (!value) {
    return '';
  }

  return format(new Date(value), 'HH:mm');
});
