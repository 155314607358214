const UserTypes = {
  FACILITADOR: 3,
  EMPRESA: 4,
  PASSAGEIRO: 2,
  ADMINISTRADOR: 5,
};

const UserStatus = {
  INATIVO: 0,
  ATIVO: 1,
};

const TripStatus = {
  SOLICITANDO: 10,
  ACEITANDO_CORRIDA: 11,
  MOTORISTA_DESLOCAMENTO: 20,
  ANDAMENTO: 30,
  FINALIZADA: 35,
  CANCELADA_MOTORISTA: 50,
  CANCELADA_PASSAGEIRO: 51,
  SEM_MOTORISTA: 55,
  SOLICITACAO_EXPIRADA: 56,
  CANCELADA_GARUPA: 57,
};

const ServiceTypes = {
  CLASSICO: 1,
  EXECUTIVO: 2,
  PET: 3,
  OBJETO: 4,
  ESTUDANTE: 5,
  SERVICO_ESPECIALIZADO: 6,
  MOTO: 7,
  CARRO_DELIVERY: 8,
  FRETE: 9,
  TAXI: 10,
  CLASSICO_KIDS: 11,
  GARUPA_SEGURO: 12,
};

const PaymentTypes = {
  DINHEIRO: 4,
  POS_CORPORATIVO: 5,
};

const AlertTypes = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export default {
  UserTypes,
  UserStatus,
  TripStatus,
  ServiceTypes,
  PaymentTypes,
  AlertTypes,
};
