<!-- eslint-disable consistent-return -->
<template>
  <div id="emails-fatura" class="center">
    <loader v-if="isLoading"></loader>
    <div v-else>
      <button id="add" class="button button-primary" @click="openNewEmailModal(uidEmpresa)" :disabled="emails.length >= 3">Adicionar</button>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Observação</th>
            <th>Principal</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!emails.length">
            <td colspan="5" class="text-center">Nenhum registro encontrado</td>
          </tr>
          <tr v-for="(item, index) in emails" :key="item.uid">
            <td class="number">{{ index + 1 }}</td>
            <td>{{ item.nome }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.observacao }}</td>
            <td><input type="checkbox" v-model="item.default" name="defaultEmpresa"
              id="txtDefaultEmpresa" size="lg" disabled></td>
            <td class="actions" witdh="90">
              <dropdown-menu right="0" width="160px">
                <template v-slot:button>
                  <div class="button button-primary">
                    <span>Ações</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
                    </svg>
                  </div>
                </template>
                <template v-slot:menu>
                  <ul>
                    <li @click="openEditEmailModal(item, uidEmpresa)">Editar</li>
                    <li @click="removeEmail(item)">Remover</li>
                  </ul>
                </template>
              </dropdown-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import garupa from '../api/garupa';
import Loader from '../components/Loader.vue';
import ConfirmationModal from '../components/ConfirmationModal.vue';
import SaveOrUpdateEmailModal from '../components/SaveOrUpdateEmailModal.vue';
import DropdownMenu from '../components/DropdownMenu.vue';

export default {
  components: { Loader, DropdownMenu },
  data() {
    return {
      isLoading: false,
      emails: [],
      uidEmpresa: '',
    };
  },
  methods: {
    openNewEmailModal(uidEmpresa) {
      this.$modal.show(SaveOrUpdateEmailModal, {
        uidEmpresa,
      }, {
        width: '800px',
        height: 'auto',
      }, {
        'before-close': async (event) => {
          if (event.params) {
            await this.emailsFatura();
          }
        },
      });
    },
    openEditEmailModal(item, uidEmpresa) {
      this.$modal.show(SaveOrUpdateEmailModal, {
        item,
        uidEmpresa,
      }, {
        width: '800px',
        height: 'auto',
      }, {
        'before-close': async (event) => {
          if (event.params) {
            await this.emailsFatura();
          }
        },
      });
    },
    removeEmail(item) {
      if (item.default) {
        this.$store.commit('showErrorAlert', 'Você não pode remover o e-mail principal');
        return;
      }
      this.$modal.show(ConfirmationModal, {
        title: 'Deseja remover o e-mail selecionado?',
      }, {
        width: '350px',
        height: 'auto',
      }, {
        'before-close': async (event) => {
          if (event.params) {
            this.isLoading = true;

            const { error, result } = await garupa.removerEmailFatura(item.uid);

            this.isLoading = false;

            if (error) {
              this.$store.commit('showErrorAlert', error);
            } else if (result) {
              this.$store.commit('showSuccessAlert', 'E-mail removido com sucesso');
            }

            await this.emailsFatura();
          }
        },
      });
    },
    async emailsFatura() {
      this.isLoading = true;

      const { error, result } = await garupa.emailsFatura();
      this.uidEmpresa = result[0].uid_empresa;

      this.isLoading = false;

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        this.emails = result || [];
      }
    },
  },
  async created() {
    await this.emailsFatura();
  },
};
</script>

<style scoped lang="scss">
  #emails-fatura {
    height: 100%;
    width: 980px;
  }

  #add {
    width: 175px;
    margin-bottom: 20px;
  }

  table {
    .text-center {
      text-align: center;
    }

    .actions,
    .number {
      word-break: normal;
    }

    .actions {
      float: right;

      .button {
        display: flex;
        border-radius: 5px;
        min-height: 35px;
        padding-right: 8px;

        span {
          display: block;
          align-self: center;
          font-weight: normal;
          line-height: 20px;
          margin-right: 5px;
        }

        svg {
          width: 18px;
          display: block;
          align-self: center;
        }
      }
    }
  }
</style>
