<template>
  <div id="favoritos">
    <loader v-if="isLoading"></loader>
    <ul v-else class="center">
      <li v-for="item in items" :key="item.uid" class="list-item">
        <div class="item">
          <div class="foto">
            <img :src="item.foto">
          </div>
          <div class="driver">
            <span class="name">{{ item.nome }}</span>
            <span class="vehicle">{{ item.marca }} {{ item.modelo }} {{ item.placa.toUpperCase() }}</span>
          </div>
          <div class="remover">
            <button title="Remover" @click="removeFavorite(item)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/>
              </svg>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import garupa from '../api/garupa';
import Loader from '../components/Loader.vue';

export default {
  components: { Loader },
  data() {
    return {
      items: null,
    };
  },
  computed: {
    isLoading() {
      return !this.items;
    },
  },
  methods: {
    async loadFavorites() {
      const { error, result } = await garupa.listFavorites();

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        this.items = result;
      }
    },
    async removeFavorite(item) {
      this.items = null;

      const { error } = await garupa.removeFavorite(item.uid);

      if (error) {
        this.$store.commit('showErrorAlert', error);
      }

      this.loadFavorites();
    },
  },
  async created() {
    await this.loadFavorites();
  },
};
</script>

<style scoped lang="scss">
  #favoritos {
    background-color: #eee;
    height: 100%;
    overflow: auto;

    ul {
      li {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        .item {
          display: flex;
          align-items: center;

          .foto {
            img {
              display: block;
              border-radius: 2px;
              width: 65px;
              height: 65px;
            }
          }

          .driver {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            .name {
              font-size: 20px;
              line-height: 28px;
            }

            .vehicle {
              font-size: 17px;
              line-height: 22px;
              color: #777;
            }
          }

          .remover {
            button {
              color: #db4d6c;
              display: block;
              cursor: pointer;
              background-color: transparent;
              border: none;
              outline: 0;
              margin: 0;

              svg {
                display: block;
                width: 45px;
              }
            }
          }
        }
      }
    }
  }
</style>
