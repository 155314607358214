<template>
  <div id="geolocation-message">
    <span class="text">O serviço de localização não está habilitado</span>
  </div>
</template>

<script>
import geolocation from '../geolocation';

export default {
  async created() {
    const state = await geolocation.permissions();

    if (state === 'denied') {
      this.$store.commit('setShowGeolocationMessage', true);
    }
  },
};
</script>

<style scoped lang="scss">
  #geolocation-message {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #25A58C;
    color: #fff;
    bottom: 0;
    padding: 5px 10px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;

    .text {
      font-size: 15px;
      text-align: center;
    }

    button {
      border-radius: 4px;
      margin-left: 10px;
      padding: 5px 10px;
      font-size: 15px;
      line-height: 18px;
      min-height: auto;
    }
  }
</style>
