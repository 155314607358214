<template>
  <div id="view-content">
    <transition name="backdrop" appear>
      <div id="backdrop" v-if="showNavigationMenu" @click="dismiss"></div>
    </transition>
    <navbar :showNavigationMenuButton="isAuthenticated" :showTripInfoMenuButton="isAuthenticated && /^\/chamar*/.test($route.path)" v-if="!['Inicio'].includes($route.name)">{{ $route.name }}</navbar>
    <div id="admin" v-if="permissions.admin">Conta Administrativa</div>
    <div id="container" :class="{ 'main': ['Inicio'].includes($route.name), 'admin': permissions.admin }">
      <div class="view">
        <slot></slot>
      </div>
      <geolocation-message v-if="showGeolocationMessage && /^\/chamar*/.test($route.path)"></geolocation-message>
      <trip-info-menu v-if="showTripInfoMenu"></trip-info-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from './Navbar.vue';
import TripInfoMenu from './TripInfoMenu.vue';
import GeolocationMessage from './GeolocationMessage.vue';

export default {
  components: { Navbar, TripInfoMenu, GeolocationMessage },
  computed: mapState(['isAuthenticated', 'showTripInfoMenu', 'showNavigationMenu', 'showGeolocationMessage', 'permissions']),
  methods: {
    dismiss() {
      this.$store.commit('setShowNavigationMenu', false);
    },
  },
};
</script>

<style scoped lang="scss">
  #view-content {
    height: 100%;
    position: relative;
  }

  #backdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 200ms;
    z-index: 90;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  #admin {
    position: absolute;
    z-index: 15;
    top: 44px;
    left: 0;
    right: 0;
    width: 100%;
    line-height: 15px;
    font-size: 10px;
    background-color: #db4d6c;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

  #container {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: transparent;

    &.main {
      top: 0;
    }

    &.admin {
      top: 59px;
    }

    .view {
      background-color: #fff;
      overflow-y: auto;
      height: 100%;
      width: 100%;
    }
  }

  .backdrop-enter {
    opacity: 0;
  }

  .backdrop-leave-active {
    opacity: 0;
  }
</style>
