<template>
  <div id="tab-view-list">
    <loader v-if="isLoading"></loader>
    <ul>
      <li v-for="item in list" :key="item.uid">
        <tab-view-item :item="item"></tab-view-item>
      </li>
    </ul>
    <div id="empty-result" v-if="!isLoading && !list.length">
      <span>{{ emptyListMessage }}</span>
    </div>
  </div>
</template>

<script>
import Loader from './Loader.vue';
import TabViewItem from './TabViewItem.vue';

export default {
  components: { Loader, TabViewItem },
  props: {
    list: Array,
    emptyListMessage: String,
  },
  computed: {
    isLoading() {
      return !this.list;
    },
  },
};
</script>

<style scoped lang="scss">
  #tab-view-list {
    padding: 15px;
    box-sizing: border-box;
    height: 100%;
  }

  #empty-result {
    display: flex;
    height: 100%;

    span {
      margin: auto;
      font-size: 18px;
      line-height: 36px;
    }
  }
</style>
