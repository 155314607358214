<template>
  <div id="resgatar-senha" class="center">
    <form @submit.prevent="submit" novalidate>
      <div class="form-field">
        <label for="info">E-mail, telefone (com DDD) ou CPF cadastrado</label>
        <input id="info" type="text" maxlength="40" v-model="userData" ref="data" :disabled="isSending">
        <span class="error" v-if="submitted && !userData.trim().length">Campo obrigatório</span>
      </div>
      <ajax-button id="send-button" class="button button-primary" type="submit" :is-disabled="isSending">Enviar</ajax-button>
    </form>
  </div>
</template>

<script>
import AjaxButton from '../components/AjaxButton.vue';
import garupa from '../api/garupa';

export default {
  components: { AjaxButton },
  data() {
    return {
      userData: '',
      isSending: false,
      submitted: false,
    };
  },
  methods: {
    async submit() {
      this.submitted = true;

      if (!this.userData.trim().length) {
        return;
      }

      this.isSending = true;

      const { error, result = {} } = await garupa.passwordRecovery(this.userData);

      if (result.mailSent) {
        this.$store.commit('showSuccessAlert', 'Enviamos um e-mail e um SMS para você recuperar a senha');
      } else {
        this.$store.commit('showErrorAlert', error);
      }

      this.isSending = false;
    },
  },
  mounted() {
    this.$refs.data.focus();
  },
};
</script>

<style scoped lang="scss">
  #resgatar-senha {
    padding: 35px 10px 0 10px;
  }

  form {
    display: flex;
    flex-flow: column;
  }

  #send-button {
    margin: auto;
    width: 100%;
    max-width: 360px;
  }
</style>
