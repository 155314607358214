<template>
  <div id="login" class="center">
    <form @submit.prevent="login" novalidate>
      <div class="form-field">
        <label for="email">E-mail</label>
        <input id="email" type="text" maxlength="40" ref="email" v-model="email" :disabled="isLoggingIn">
        <span class="error" v-if="submitted && !email.trim().length">Campo obrigatório</span>
      </div>
      <div class="form-field">
        <label for="password">Senha</label>
        <input id="password" type="password" maxlength="255" v-model="password" :disabled="isLoggingIn">
        <span class="error" v-if="submitted && !password.trim().length">Campo obrigatório</span>
      </div>
      <ajax-button id="btn-login" class="button button-primary" type="submit" :is-disabled="isLoggingIn">Log in</ajax-button>
    </form>
    <router-link id="forgot-password" to="/resgatar-senha">Esqueci minha senha</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import geolocation from '../geolocation';
import AjaxButton from '../components/AjaxButton.vue';
import constants from '../constants';
import garupa from '../api/garupa';
import store from '../store/index';

const { UserTypes } = constants;

export default {
  components: { AjaxButton },
  data() {
    return {
      email: '',
      password: '',
      isLoggingIn: false,
      submitted: false,
    };
  },
  computed: mapState(['geolocationWatchId']),
  methods: {
    async loginOnly() {
      const { error, result } = await garupa.login(this.email, this.password);
      if (result) {
        store.commit('setUserData', result);
        return true;
      }
      return error;
    },
    async login() {
      this.submitted = true;

      if (!this.email.trim().length || !this.password.trim().length) {
        return;
      }
      this.isLoggingIn = true;
      const { error, result } = await garupa.login(this.email, this.password);

      if (result) {
        if (result.tipo === UserTypes.FACILITADOR || result.tipo === UserTypes.EMPRESA || result.tipo === UserTypes.ADMINISTRADOR) {
          store.commit('setUserData', result);

          localStorage.setItem('userData', JSON.stringify(result));

          await this.setFcmId();
          await this.setSettings();
          await this.setPermissions();

          store.commit('setIsAuthenticated', true);

          this.$router.push('/chamar');
        } else {
          this.isLoggingIn = false;
          store.commit('showWarningAlert', 'Tipo de conta sem permissão de acesso ao sistema');
        }
      } else {
        this.isLoggingIn = false;
        store.commit('showErrorAlert', error);
      }
    },
    async setFcmId() {
      const { error } = await garupa.setFcmId();

      if (error) {
        store.commit('showErrorAlert', error);
      }
    },
    async setSettings() {
      const { error, result } = await garupa.configuracao();

      if (error) {
        store.commit('showErrorAlert', error);
      } else if (result) {
        const settings = result;

        settings.lat_localizacao_atual = settings.lat_localizacao_atual ? parseFloat(settings.lat_localizacao_atual) : null;
        settings.lng_localizacao_atual = settings.lng_localizacao_atual ? parseFloat(settings.lng_localizacao_atual) : null;

        store.commit('setSettings', settings);
        localStorage.setItem('settings', JSON.stringify(settings));

        const { lat_localizacao_atual: lat, lng_localizacao_atual: lng } = settings;

        if (lat && lng) {
          if (this.geolocationWatchId) {
            geolocation.clearWatch(this.geolocationWatchId);
          }

          store.commit('setCurrentPosition', { lat, lng });
        }
      }
    },
    async setPermissions() {
      const { error, result } = await garupa.permissoes();

      if (error) {
        store.commit('showErrorAlert', error);
      } else if (result && result.permissoes) {
        store.commit('setPermissions', JSON.parse(result.permissoes));
      }
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
};
</script>

<style scoped lang="scss">
  form {
    display: flex;
    flex-direction: column;
  }

  #btn-login {
    margin: auto;
    width: 100%;
    max-width: 360px;
  }

  #forgot-password {
    color: #25a58c;
    font-weight: bold;
    text-align: center;
    display: block;
    font-size: 16px;
    line-height: 47px;
    margin-top: 25px;
  }
</style>
