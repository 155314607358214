<template>
  <div id="estimativa">
    <div id="box-estimate">
      <span id="title-estimate">Estimativa</span>
      <div id="estimate">
        <div>
          <span class="title">Duração</span>
          <span class="value">{{ duration | formatDuration }}</span>
        </div>
        <div>
          <span class="title">Valor estimado</span>
          <span class="value">{{ service.price | formatPrice }}</span>
        </div>
        <div>
          <span class="title">Distância</span>
          <span class="value">{{ distance | formatDistance }}</span>
        </div>
      </div>
      <item-selector :items="servicesListSelect" :item="service" title="Garupa" @selected-item="selectedService">
        {{ service.type | formatServiceType }}
      <span v-if="service.type === 6" style="margin-top: 0.1em;float: right;margin-left: 0.4em;">
        <a :title="servicosEspecializados[service.uid]">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style="fill:#25a58c;">
            <path
              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
          </svg>
        </a>
      </span>
      </item-selector>
      <item-selector :items="paymentTypes" :item="payment" title="Forma de pagamento" @selected-item="selectedPayment">
        {{ payment.type | formatPaymentType }}
      </item-selector>
    </div>
    <ajax-button id="btn-call" class="button button-primary" :is-disabled="userData.empresa === null && userData.tipo === 4" @click.native="nextStep">
      Chamar Garupa
    </ajax-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import L from 'leaflet';
import polyUtil from 'polyline-encoded';
import constants from '../constants';
import garupa from '../api/garupa';
import store from '../store/index';
import ItemSelector from '../components/ItemSelector.vue';
import AjaxButton from '../components/AjaxButton.vue';

const { TripStatus, PaymentTypes } = constants;

export default {
  components: { ItemSelector, AjaxButton },
  filters: {
    formatDistance(distance = 0) {
      return distance < 1000 ? `${Math.round(distance)} m` : `${(distance / 1000).toFixed(1)} Km`;
    },
    formatDuration(duration = 0) {
      return duration < 60 ? `${Math.round(duration)} seg` : `${Math.round(duration / 60)} min`;
    },
  },
  data() {
    return {
      duration: null,
      distance: null,
      service: {},
      payment: {},
      tripRequest: null,
      polyline: null,
      services: [],
      paymentTypes: [],
      isLoadingData: true,
      updatedPayment: [],
      servicosEspecializados: {},
      servicoEmpresa: '',
      servicesListSelect: [],
    };
  },
  computed: mapState(['map', 'origin', 'destination', 'blockRequest', 'settings', 'userData']),
  methods: {
    setServiceTypes(values) {
      this.services = Object.keys(values).reduce((result, paymentType) => {
        const key = result;
        key[paymentType] = values[paymentType].map((service) => ({
          type: Number(service.codigo),
          price: service.valor.toFixed(2),
          uid: service.uid,
          description_service: this.buscaServicoEspecializado(service.uid, Number(service.codigo)),
        })).sort((a, b) => (a.type - b.type));
        return result;
      }, {});

      this.setServiceType();
    },
    convertPaymentTypes(values) {
      if (typeof values === 'string') {
        return JSON.parse(values).map((item) => ({ type: item }));
      }
      return Object.values(values).map((item) => ({ type: item }));
    },
    async setPaymentTypes(values) {
      const { result } = await garupa.metodoPagamento(this.userData.uid);
      this.paymentTypes = result ? this.convertPaymentTypes(result.tipos_pagamento) || [] : this.convertPaymentTypes(values);
      this.setPaymentType();
    },
    setPaymentType() {
      const paymentType = this.settings.tipo_pagamento_preferencial;

      const index = _.findIndex(this.paymentTypes, (payment) => (
        payment.type === paymentType
      ));
      this.handlePayment();
      if (index !== -1) {
        const payments = this.arrayMove(this.paymentTypes, index, 0);
        this.paymentTypes = payments.filter((i) => i);
      }
      this.payment = this.paymentTypes[0] || {};
    },
    handlePayment() {
      Object.keys(this.paymentTypes).forEach((item) => {
        if ((this.userData.tipo === 3 && this.paymentTypes[item].type === 4 && this.userData.empresa == null) // 3 facilitador, 4 dinheiro e não estar associado a empresa
          || (this.userData.tipo === 3 && this.userData.empresa !== null && (this.paymentTypes[item].type === 4 || this.paymentTypes[item].type === 5)) // 3 facilitador, se estiver vinculado à uma empresa, 4 dinheiro e 5 pós-pago
          || (this.userData.tipo === 4 && this.userData.empresa !== null && this.paymentTypes[item].type === 5)) { //  4 empresa, estar associado a uma empresa, 5 pós pago
          this.updatedPayment.push(this.paymentTypes[item]);
        }
      });
      if (this.updatedPayment) {
        this.paymentTypes = this.updatedPayment;
      }
    },
    setServiceType(serviceToSet) {
      const { tipo_servico_preferencial: preferredServiceType } = this.settings;
      const paymentServices = this.services[this.payment.type];
      const preferredIndex = paymentServices.findIndex((service) => service.type === preferredServiceType);
      this.servicesListSelect = preferredIndex !== -1
        ? this.arrayMove(paymentServices, preferredIndex, 0)
        : [...paymentServices];

      this.service = serviceToSet
        ? this.servicesListSelect.find((service) => service.type === serviceToSet)
        : this.servicesListSelect[0];
    },
    arrayMove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    },
    async estimativa() {
      this.isLoadingData = true;

      const { error, result } = await garupa.estimativa(this.origin, this.destination);

      if (result) {
        this.isLoadingData = false;
        const { duration, distance, encodedPath } = result;
        this.duration = duration;
        this.distance = distance;
        this.polyline = L.polyline(polyUtil.decode(encodedPath), { color: '#18BC9C', fillOpacity: 0.9, strokeWeight: 5 }).addTo(this.map);
        this.map.fitBounds(this.polyline.getBounds());
      } else {
        this.$store.commit('showErrorAlert', error);
        this.$router.push('/chamar/destino');
      }
    },
    async preco() {
      if (typeof this.distance === 'number' && typeof this.duration === 'number') {
        this.isLoadingData = true;

        const { error, result } = await garupa.preco(this.distance, this.duration, this.origin, this.destination);

        if (result) {
          this.isLoadingData = false;

          this.setServiceTypes(result.valores);
        } else {
          this.$store.commit('showErrorAlert', error);
          this.$router.push('/chamar/destino');
        }
      }
    },
    async solicitar() {
      if (!_.isEmpty(this.payment) && !_.isEmpty(this.service)) {
        this.isLoadingData = true;

        const { error, result } = await garupa.solicitar(
          this.distance,
          this.duration,
          this.origin,
          this.destination,
          this.payment,
          this.service,
        );

        if (result) {
          this.tripRequest = { uid: result.data.uid };
        } else {
          this.$store.commit('showErrorAlert', error);
        }

        this.isLoadingData = false;
      }
    },
    async callDriver() {
      if (this.tripRequest) {
        this.isLoadingData = true;

        const { error, result = {} } = await garupa.callDriver(this.tripRequest.uid);

        if (result.success) {
          this.tripRequest.status = TripStatus.SOLICITANDO;
          this.tripRequest.expiresAt = Date.now() + result.expiresIn;
          this.tripRequest.origin = this.origin;

          this.$store.commit('setTrip', this.tripRequest);

          localStorage.setItem('currentTripRequest', JSON.stringify(this.tripRequest));
        } else {
          this.$store.commit('showErrorAlert', error);
        }

        this.isLoadingData = false;
      }
    },
    async buscaServicoEspecializado(uid, tipo) {
      if (tipo === 6) {
        const { error, result } = await garupa.buscaServicoEspecializado(uid);
        if (result) {
          this.servicosEspecializados[uid] = result;
          return result;
        }
        this.$store.commit('showErrorAlert', error);
      }
      return {};
    },

    async buscaServicoEmpresa() {
      if (this.userData.empresa) {
        const uidCidade = await garupa.getUidCidade(this.origin.lat, this.origin.lng);
        if (uidCidade.result && uidCidade.result === this.userData.uid_cidade) {
          const servico = await garupa.buscaServicoEmpresa(this.userData.empresa);
          this.servicoEmpresa = servico.result;
        }
      }
    },

    selectedService(service) {
      this.service = service;
    },
    selectedPayment(payment) {
      this.payment = payment;
      this.setServiceType(this.service.type);
    },
    async nextStep() {
      if (this.blockRequest) {
        this.$store.commit('showWarningAlert', 'Aguarde a solicitação atual ser finalizada');
        return;
      }

      await this.solicitar();
      await this.callDriver();

      if (this.tripRequest && this.tripRequest.status === TripStatus.SOLICITANDO) {
        this.$store.commit('showInfoAlert', 'Procurando motorista...');
        this.$router.push('/chamar/chamar-motorista');
      } else {
        this.isLoadingData = false;
      }
    },
  },
  async created() {
    await this.setPaymentTypes(PaymentTypes);
    await this.estimativa();
    await this.buscaServicoEmpresa();
    await this.preco();
  },
  beforeRouteEnter(to, from, next) {
    const { currentTripRequest, blockRequest, origin, destination } = store.state;

    if (currentTripRequest && !blockRequest) {
      next('/chamar/chamar-motorista');
    } else if (origin && destination) {
      next();
    } else {
      next('/chamar');
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.polyline) {
      this.map.removeLayer(this.polyline);
    }

    next();
  },
};
</script>

<style scoped lang="scss">
  #box-estimate {
    position: absolute;
    z-index: 15;
    background-color: #fff;
    left: 50%;
    bottom: 100px;
    width: 100%;
    max-width: 680px;
    box-shadow: 0 0 8px -5px #333;
    transform: translate(-50%, 0);
  }

  #title-estimate {
    text-align: center;
    width: 100%;
    display: block;
    padding: 5px 0;
    font-size: 16px;
    box-sizing: border-box;
    background-color: #25a58c;
    color: #fff;
  }

  #estimate {
    display: flex;
    justify-content: space-around;
    text-align: center;
    box-sizing: border-box;
    padding: 5px;

    .title {
      display: block;
      line-height: 20px;
      color: #25a58c;
    }

    .value {
      display: block;
      line-height: 45px;
      height: 45px;
      color: #000;
    }
  }

  #btn-call {
    position: absolute;
    bottom: 35px;
    width: 100%;
    max-width: 300px;
    left: 50%;
    z-index: 15;
    transform: translate(-50%, 0);
    box-shadow: 0 0 8px -5px #333;
  }
</style>
