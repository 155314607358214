<template>
  <div id="manage-payment-type-modal" class="modal">
    <div class="header">
      <span class="text">Gerenciar Formas de Pagamento</span>
      <button class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </button>
    </div>
    <div class="body">
      <form @submit.prevent="changePaymentTypes">
        <div class="form-field" v-for="paymentType in paymentTypes" :key="paymentType">
          <label :for="paymentType">{{ paymentType | formatPaymentType }}</label>
          <input :id="paymentType" type="checkbox" :value="paymentType" v-model="selectedPaymentTypes" :disabled="isLoading">
        </div>
        <ajax-button class="button button-primary" type="submit" :is-disabled="isLoading">Salvar</ajax-button>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import constants from '../constants';
import garupa from '../api/garupa';
import AjaxButton from './AjaxButton.vue';

const { PaymentTypes } = constants;

export default {
  components: { AjaxButton },
  props: {
    user: Object,
  },
  data() {
    return {
      paymentTypes: [],
      selectedPaymentTypes: [],
      isLoading: false,
    };
  },
  methods: {
    async changePaymentTypes() {
      if (!this.selectedPaymentTypes.length) {
        this.$store.commit('showErrorAlert', 'Selecione pelo menos uma forma de pagamento');
        return;
      }

      this.isLoading = true;

      const { error } = await garupa.salvarMetodoPagamento(this.user.uid, this.selectedPaymentTypes);

      this.isLoading = false;

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        this.$store.commit('showSuccessAlert', 'Formas de pagamento salvas com sucesso');
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
  },
  async created() {
    this.paymentTypes = PaymentTypes;

    this.isLoading = true;

    const { error, result } = await garupa.metodoPagamento(this.user.uid);

    this.isLoading = false;

    if (error) {
      this.$store.commit('showErrorAlert', error);
    } else {
      this.selectedPaymentTypes = result ? JSON.parse(result.tipos_pagamento) || [] : _.map(PaymentTypes, (type) => (type));
    }
  },
};
</script>

<style scoped lang="scss">
  form {
    display: flex;
    flex-direction: column;
    padding: 30px;

    .form-field {
      display: flex;

      label {
        line-height: 22px;
        margin: 0;
      }

      input {
        margin: 0 0 0 10px;
        padding: 0;
        align-self: center;
      }
    }

    button {
      width: 100%;
      max-width: 360px;
      margin: auto;
    }
  }
</style>
