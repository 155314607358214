<template>
  <div id="started-trips-tab">
    <paginator-view @paginate="loadMore">
      <tab-view-list :list="content.data" emptyListMessage="Nenhuma corrida iniciada"></tab-view-list>
    </paginator-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PaginatorView from './PaginatorView.vue';
import TabViewList from './TabViewList.vue';
import garupa from '../api/garupa';

export default {
  components: { PaginatorView, TabViewList },
  computed: mapState(['currentPosition']),
  data() {
    return {
      position: {},
      content: {},
    };
  },
  methods: {
    async loadMore() {
      if (this.content.current_page + 1 <= this.content.last_page) {
        const { error, result } = await garupa.corridasIniciadas(
          this.position.lat,
          this.position.lng,
          this.content.current_page + 1,
        );

        if (result) {
          const currentList = this.content.data;

          this.content = result.data;

          this.content.data = [...currentList, ...result.data.data];
        } else {
          this.$store.commit('showErrorAlert', error);
        }
      }
    },
  },
  async created() {
    const { lat, lng } = this.currentPosition;

    this.position = { lat, lng };

    const { error, result } = await garupa.corridasIniciadas(lat, lng);

    if (result) {
      this.content = result.data;
    } else {
      this.$store.commit('showErrorAlert', error);
    }
  },
};
</script>

<style scoped lang="scss">
  #started-trips-tab {
    height: 100%;
  }
</style>
