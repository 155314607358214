<template>
  <div id="change-password-modal" class="modal">
    <div class="header">
      <span class="text">Alterar Senha</span>
      <button class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </button>
    </div>
    <div class="body">
      <form @submit.prevent="changePassword">
        <div class="form-field">
          <label for="password">Nova senha</label>
          <input id="password" class="text" type="password" v-model="password" :disabled="isLoading" maxlength="40" ref="password">
          <span class="error" v-if="submitted && !password.trim()">Campo obrigatório</span>
        </div>
        <ajax-button class="button button-primary" type="submit" :is-disabled="isLoading">Salvar</ajax-button>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import garupa from '../api/garupa';
import AjaxButton from './AjaxButton.vue';

export default {
  components: { AjaxButton },
  props: {
    user: Object,
  },
  data() {
    return {
      isLoading: false,
      submitted: false,
      password: '',
    };
  },
  methods: {
    async changePassword() {
      this.submitted = true;

      if (!this.password.trim()) {
        return;
      }

      this.isLoading = true;

      const { error, result } = await garupa.alterarSenha(this.user.uid, this.password);

      this.isLoading = false;

      if (result) {
        this.$store.commit('showSuccessAlert', 'Senha alterada com sucesso');
        this.$emit('close');
      } else if (error.errors) {
        _.forEach(error.errors, (value) => {
          this.$store.commit('showWarningAlert', value[0]);
        });
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    this.$refs.password.focus();
  },
};
</script>

<style scoped lang="scss">
  form {
    display: flex;
    flex-direction: column;
    padding: 30px;

    .button {
      width: 100%;
      max-width: 360px;
      margin: auto;
    }
  }
</style>
