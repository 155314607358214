import Vue from 'vue';
import VueRouter from 'vue-router';
import Inicio from '../views/Inicio.vue';
import Login from '../views/Login.vue';
import Termos from '../views/Termos.vue';
import ResgatarSenha from '../views/ResgatarSenha.vue';
import Chamar from '../views/Chamar.vue';
import Perfil from '../views/Perfil.vue';
import Configuracoes from '../views/Configuracoes.vue';
import CriarUsuario from '../views/CriarUsuario.vue';
import AprovarFaturas from '../views/AprovarFaturas.vue';
import EmailsFatura from '../views/EmailsFatura.vue';
import GerenciarUsuarios from '../views/GerenciarUsuarios.vue';
import Favoritos from '../views/Favoritos.vue';
import Suporte from '../views/Suporte.vue';
import Historico from '../views/Historico.vue';
import ParaOnde from '../views/ParaOnde.vue';
import Destino from '../views/Destino.vue';
import Estimativa from '../views/Estimativa.vue';
import ChamarMotorista from '../views/ChamarMotorista.vue';
import store from '../store';
import garupa from '../api/garupa';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Inicio,
    name: 'Inicio',
  }, {
    path: '/login',
    component: Login,
    name: 'Login',
  }, {
    path: '/termos',
    component: Termos,
    name: 'Termos',
  }, {
    path: '/termos-de-uso',
    component: Termos,
    name: 'Termos de Uso',
  }, {
    path: '/resgatar-senha',
    component: ResgatarSenha,
    name: 'Resgatar Senha',
  }, {
    path: '/perfil',
    component: Perfil,
    name: 'Perfil',
  }, {
    path: '/configuracoes',
    component: Configuracoes,
    name: 'Configurações',
  }, {
    path: '/criar-usuario',
    component: CriarUsuario,
    name: 'Criar Usuário',
  }, {
    path: '/gerenciar-usuarios',
    component: GerenciarUsuarios,
    name: 'Gerenciar Usuários',
  }, {
    path: '/aprovar-faturas',
    component: AprovarFaturas,
    name: 'Aprovar Faturas',
  }, {
    path: '/emails-fatura',
    component: EmailsFatura,
    name: 'E-mails de Fatura',
  }, {
    path: '/favoritos',
    component: Favoritos,
    name: 'Favoritos',
  }, {
    path: '/suporte',
    component: Suporte,
    name: 'Suporte e Ajuda',
  }, {
    path: '/historico',
    component: Historico,
    name: 'Histórico',
  }, {
    path: '/chamar',
    component: Chamar,
    children: [
      {
        path: '',
        name: 'Chamar Garupa',
        component: ParaOnde,
      }, {
        path: 'destino',
        name: 'Destino',
        component: Destino,
      }, {
        path: 'estimativa',
        name: 'Estimativa',
        component: Estimativa,
      }, {
        path: 'chamar-motorista',
        name: 'Chamar Motorista',
        component: ChamarMotorista,
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
});

const unrestrictedRoutes = ['Inicio', 'Login', 'Resgatar Senha', 'Termos'];

router.beforeEach(async (to, from, next) => {
  const { state } = store;

  store.commit('setShowNavigationMenu', false);

  if (!state.userData) {
    const storage = localStorage.getItem('userData');

    if (storage) {
      store.commit('setUserData', JSON.parse(storage));
    }
  }

  if (!unrestrictedRoutes.includes(to.name) && !state.isAuthenticated) {
    if (state.userData) {
      const { error, result } = await garupa.checkAuth();

      if (result) {
        store.commit('setUserData', result);

        localStorage.setItem('userData', JSON.stringify(result));

        const { error: err } = await garupa.setFcmId();

        if (err) {
          store.commit('showErrorAlert', err);
        }

        const { error: e, result: r } = await garupa.permissoes();

        if (e) {
          store.commit('showErrorAlert', e);
        } else if (r && r.permissoes) {
          store.commit('setPermissions', JSON.parse(r.permissoes));
        }

        store.commit('setIsAuthenticated', true);

        next();
      } else {
        localStorage.removeItem('userData');
        store.commit('showErrorAlert', error);
        setTimeout(() => {
          next('/');
        }, 2000);
      }
    } else {
      next('/');
    }
  } else if (unrestrictedRoutes.includes(to.name) && state.userData) {
    next('/chamar');
  } else {
    next();
  }
});

export default router;
