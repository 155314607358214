<template>
  <div id="criar-usuario" class="center">
    <form @submit.prevent="submit">
      <div class="picture">
        <label for="file">
          <div class="image-wrapper">
            <img :src="user.picture">
          </div>
          <span>Escolha uma foto</span>
          <input id="file" type="file" ref="file" accept=".jpg,.jpeg,.png" @change="uploadImage" :disabled="isLoading">
        </label>
      </div>
      <div class="form-field">
        <label for="first-name">Nome</label>
        <input id="first-name" type="text" maxlength="50" v-model="user.firstName" :disabled="isLoading" required>
      </div>
      <div class="form-field">
        <label for="last-name">Sobrenome</label>
        <input id="last-name" type="text" maxlength="50" v-model="user.lastName" :disabled="isLoading" required>
      </div>
      <div class="form-field">
        <label for="email">E-mail
        <span title="O e-mail possui um limite de 135 caracteres.">
          <svg style="" width="14" height="14" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 20.5639C40 13.1406 37.6564 13.6216 32.6701 9.88643C32.3903 9.6769 32.2792 9.58282 32.0121 9.34549C30.6171 8.10113 30.3223 7.68849 29.2007 6.00369C28.5512 5.02873 27.9232 4.0046 27.2096 3.04247C25.2634 0.414781 23.1783 0 19.7516 0C14.2825 0 13.4664 2.08674 10.8728 5.97589C6.16645 13.0272 1.1994 11.2548 0.182495 17.6455C-0.176413 19.8969 0.00303969 22.3663 0.641812 24.2307C1.74204 27.4464 6.27967 28.6929 8.86039 31.4681L9.50985 32.1694C13.4792 36.8389 12.5072 40.5934 21.9947 39.922C22.7873 39.8664 24.3597 39.4559 24.9472 39.1224C26.5025 38.2394 27.4467 36.6401 28.3953 35.2183C31.5742 30.4461 31.5656 30.8395 36.0456 27.8633C38.951 25.9262 40 24.297 40 20.5639Z" fill="#039782"/>
            <path d="M19.5 9C18.0143 9 16.7143 10.2833 16.7143 11.75C16.7143 13.2167 18.0143 14.5 19.5 14.5C20.9857 14.5 22.2857 13.2167 22.2857 11.75C22.2857 10.2833 20.9857 9 19.5 9ZM13 16.3333V18.1667C13 18.1667 16.7143 18.1667 16.7143 21.8333V25.5C16.7143 29.1667 13 29.1667 13 29.1667V31H26V29.1667C26 29.1667 22.2857 29.1667 22.2857 25.5V18.1667C22.2857 17.25 21.3571 16.3333 20.4286 16.3333H13Z" fill="white"/>
          </svg>
        </span>
      </label>
        <input id="email" type="text" maxlength="135" v-model="user.email" :disabled="isLoading" required>
      </div>
      <div class="form-field">
        <label for="password">Senha</label>
        <input id="password" type="password" maxlength="40" v-model="user.password" :disabled="isLoading" required>
      </div>
      <div class="form-field">
        <label for="gender">Sexo</label>
        <div class="select" :class="{ 'disabled': isLoading }">
          <select id="gender" v-model="user.gender" :disabled="isLoading" required>
            <option value="" disabled selected>Selecione o sexo</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
            <option value="O">Outro</option>
          </select>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
          </svg>
        </div>
      </div>

      <div class="form-field">
        <label for="cpf">CPF</label>
        <input id="cpf" type="text" v-model="user.cpf" :disabled="isLoading" v-mask="'###.###.###-##'">
        <span class="error" v-if="user.cpf.trim().length && !validarCPF(user.cpf.replaceAll('.', '').replace('-', ''))">CPF inválido</span>
      </div>

      <div class="form-field">
        <label for="telefone">Telefone</label>
        <input id="telefone" type="text" v-model="user.telefone" :disabled="isLoading" v-mask="'(##) ##### ####'" required>
      </div>

      <div class="form-field">
        <label for="tipo">Perfil</label>
        <div class="select" :class="{ 'disabled': isLoading }">
          <select id="tipo" v-model="user.tipo" :disabled="isLoading" required>
          <option value="" disabled selected>Selecione o perfil</option>
          <option v-for="type in userTypes" :value="type.value" :key="type.value">
            {{ type.value | formatUserType }}
          </option>
          </select>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
          </svg>
        </div>
      </div>

      <div class="form-field">
        <label for="empresa">Empresa</label>
          <div v-if="empresa_associada">
            <input type="text" :value="user.nome_empresa" readonly>
          </div>
          <div v-else>
          <div class="select" :class="{ 'disabled': isLoading }">
            <select id="empresa" v-model="user.empresa" :disabled="isLoading" :required="user.tipo === 4 && empresas.length > 0">
              <option value="" selected>Selecione a empresa</option>
              <option v-for="empresa in empresas" v-bind:key="empresa.uid" v-bind:value="empresa.uid">{{empresa.nome}}</option>
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
            </svg>
          </div>
        </div>
      </div>
      <div v-if="cidade_associada">
        <div class="form-field row">
          <label for="empresa">Cidade</label>
          <input type="text" :value="user.nome_cidade" readonly>
          <button class="button button-primary" @click="removeCidadeAssociada()"
             style="position: relative; top: -2.95em; right: 0.3em; width: 14em; float: right;">
            Alterar Cidade
          </button>
        </div>
      </div>

      <div v-else>
        <div class="form-field">
          <label for="pais">País</label>
          <div class="select" :class="{ 'disabled': isLoading }" >
            <select id="pais" v-model="user.pais" :disabled="isLoading" @change="buscaEstados(user.pais)" required>
              <option value="" disabled selected>Selecione o país</option>
              <option v-for="pais in paises" v-bind:key="pais.uid" v-bind:value="pais.uid">{{pais.nome}}</option>
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
            </svg>
          </div>
        </div>

        <div class="form-field">
          <label for="estado">Estado</label>
            <div class="select" :class="{ 'disabled': isLoading }">
              <select  id="estado" v-model="user.estado" :disabled="isLoading" @change="buscaCidades(user.estado)" required>
                <option value="" disabled selected>Selecione o estado</option>
                <option v-for="estado in estados" v-bind:key="estado.uid" v-bind:value="estado.uid" >{{estado.nome}}</option>
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
              </svg>
            </div>
        </div>

        <div class="form-field">
          <label for="cidade">Cidade</label>
            <div class="select" :class="{ 'disabled': isLoading }">
              <select  id="cidade" v-model="user.cidade" :disabled="isLoading" required>
                <option value="" disabled selected>Selecione a cidade</option>
                <option v-for="cidade in cidades" v-bind:key="cidade.uid" v-bind:value="cidade.uid" >{{cidade.nome}}</option>
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
              </svg>
            </div>

        </div>
      </div>

      <div class="form-field">
        <label for="bairro">Bairro</label>
        <input id="bairro" type="text" maxlength="40" v-model="user.bairro" :disabled="isLoading">
      </div>
      <ajax-button class="button button-primary" type="submit">Salvar</ajax-button>

    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { mask } from 'vue-the-mask';
import store from '../store/index';
import garupa from '../api/garupa';
import AjaxButton from '../components/AjaxButton.vue';
import constants from '../constants';

const { UserTypes } = constants;

export default {
  components: { AjaxButton },
  directives: { mask },
  data() {
    return {
      isLoading: false,
      submitted: false,
      defaultPicture: '',
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        gender: '',
        picture: '',
        cpf: '',
        telefone: '',
        tipo: '',
        pais: '',
        estado: '',
        cidade: '',
        bairro: '',
        empresa: '',
      },
      empresa_associada: '',
      cidade_associada: '',
      paises: [],
      estados: [],
      cidades: [],
      empresas: [],
      userTypes: [],
      updatedUserType: [],
    };
  },
  computed: mapState(['userData']),
  methods: {
    uploadImage(event) {
      const { files } = event.target;

      if (files && files.length) {
        const file = files[0];
        const reader = new FileReader();

        if (file.size > 1024 * 1024 * 2) {
          this.$store.commit('showErrorAlert', 'Somente imagens com menos de 2mb podem ser enviadas');

          this.$refs.file.value = '';

          return;
        }

        reader.onload = (e) => {
          this.user.picture = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    },
    async buscaEstados(pais) {
      this.estados = [{ uid: '', nome: 'Carregando...' }];
      const { result } = await garupa.listarEstados(pais);
      this.estados = result.estados;
    },
    async buscaCidades(estado) {
      this.cidades = [{ uid: '', nome: 'Carregando...' }];
      const { result } = await garupa.listarCidades(estado);
      this.cidades = result.cidades;
    },
    validarCPF(inputCPF) {
      let soma = 0;
      let i;
      let resto;
      for (i = 1; i <= 9; i += 1) {
        soma += parseInt(inputCPF.substring(i - 1, i), 10) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(inputCPF.substring(9, 10), 10)) return false;

      soma = 0;
      for (i = 1; i <= 10; i += 1) {
        soma += parseInt(inputCPF.substring(i - 1, i), 10) * (12 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(inputCPF.substring(10, 11), 10)) return false;
      return true;
    },
    async submit() {
      this.isLoading = true;

      const { error = {}, result } = await garupa.criarUsuario(
        this.user.firstName,
        this.user.lastName,
        this.user.gender,
        this.user.email,
        this.user.password,
        this.user.picture,
        this.user.cpf.replaceAll('.', '').replace('-', ''),
        this.user.tipo,
        this.user.pais,
        this.user.estado,
        this.user.cidade,
        this.user.bairro,
        this.user.telefone.replaceAll(' ', '').replace('(', '').replace(')', ''),
        this.user.empresa,
      );

      this.isLoading = false;

      if (result) {
        this.$store.commit('showSuccessAlert', 'Usuário criado com sucesso');

        this.$refs.file.value = '';

        this.user = {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          gender: '',
          picture: this.defaultPicture,
          cpf: '',
          tipo: '',
          telefone: '',
          pais: this.user.pais,
          estado: this.user.estado,
          cidade: this.user.cidade,
          bairro: '',
          empresa: '',
        };
        setTimeout(() => this.$router.go(), 2500);
      } else if (error.errors) {
        _.forEach(error.errors, (value) => {
          this.$store.commit('showWarningAlert', value[0]);
        });
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
    imageToBase64(callback) {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.height = img.width;
        canvas.width = img.height;

        context.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('jpg');

        callback(dataURL);
      };

      img.src = require('@/assets/img/facilitador.jpg');
    },
    setUserTypes() {
      this.userTypes = _.map(UserTypes, (value) => ({
        value,
      }));
      this.trataUserTypes();
    },

    trataUserTypes() {
      Object.keys(this.userTypes).forEach((item) => {
        if (this.validaFacilitadorAdministrador(this.userTypes[item].value) || this.validaEmpresa(this.userTypes[item].value)) { // usuário logado = 4 empresa | Pode cadastrar: 4 empresa
          this.updatedUserType.push(this.userTypes[item]);
        }
      });
      if (this.updatedUserType) {
        this.userTypes = this.updatedUserType;
      }
    },

    validaFacilitadorAdministrador(itemType) {
      const userData = [3, 5]; // facilitador e administrador
      if (!userData.includes(this.userData.tipo)) {
        return false;
      }
      const userTypes = [2, 3, 4]; // passageiro, facilitador e empresa
      return userTypes.includes(itemType);
    },

    validaEmpresa(itemType) {
      return (this.userData.tipo === 4 && (itemType === 4 || itemType === 2)); // empresa
    },
    removeCidadeAssociada() {
      this.cidade_associada = false;
      this.user.cidade = '';
      this.user.estado = '';
      this.user.pais = '';
    },
  },
  async created() {
    this.imageToBase64((data) => {
      this.defaultPicture = data;
      this.user.picture = data;
    });
    this.paises = [{ uid: '', nome: 'Carregando...' }];
    const { result } = await garupa.listarPaises();
    this.paises = result.paises;

    this.empresas = [{ uid: '', nome: 'Carregando...' }];
    const { resulta } = await garupa.listarEmpresas(this.userData.uid, this.userData.tipo);
    if (resulta.cidade_associada) {
      this.cidade_associada = true;
      this.user.cidade = resulta.cidade_associada.uid_cidade;
      this.user.nome_cidade = resulta.cidade_associada.nome_cidade;
      this.user.estado = resulta.cidade_associada.uid_estado;
      this.user.nome_estado = resulta.cidade_associada.nome_estado;
      this.user.pais = resulta.cidade_associada.uid_pais;
      this.user.nome_pais = resulta.cidade_associada.nome_pais;
    }
    this.empresas = resulta.empresas;

    if (resulta.empresa_associada !== null && Object.keys(resulta.empresa_associada).length > 0) {
      this.empresa_associada = true;
      this.user.empresa = resulta.empresa_associada.uid_empresa;
      this.user.nome_empresa = resulta.empresa_associada.nome;
    }
    this.setUserTypes();
  },
  beforeRouteEnter(to, from, next) {
    const { permissions } = store.state;

    if (permissions.admin) {
      next();
    } else {
      next('/');
    }
  },

};
</script>

<style scoped lang="scss">
  #criar-usuario {
    form {
      display: flex;
      flex-flow: column;

      .picture {
        margin-bottom: 15px;

        label {
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .image-wrapper {
            width: 250px;
            height: 250px;
            border-radius: 50%;
            align-self: center;
            overflow: hidden;
            background-color: #eee;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          input[type=file] {
            display: none;
          }
        }

        span {
          display: block;
          text-align: center;
          color: #333;
          line-height: 22px;
          margin-top: 10px;
        }
      }

      .button {
        width: 100%;
        max-width: 360px;
        margin: auto;
        min-height: 30px;
      }

    }
  }
</style>
