<template>
  <div id="chamar-garupa">
    <button id="btn-destination" class="button" @click="nextStep">Para Onde?</button>
  </div>
</template>

<script>
import store from '../store/index';

export default {
  methods: {
    nextStep() {
      this.$router.push('/chamar/destino');
    },
  },
  beforeRouteEnter(to, from, next) {
    const { currentTripRequest, blockRequest } = store.state;

    if (currentTripRequest && !blockRequest) {
      next('/chamar/chamar-motorista');
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
#btn-destination {
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: 15;
  top: 80px;
  box-shadow: 0 1px 8px -5px #333;
  color: #db4d6c;
  max-width: 225px;
  width: 100%;
}
</style>
