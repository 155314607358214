<template>
  <div id="app">
    <div id="garupa">
      <img src="@/assets/img/garupa-logo-slogan.png" alt="Garupa">
    </div>
    <navigation-menu v-if="displayNavigationMenu"></navigation-menu>
    <div id="container" class="side-menu" :class="{ 'side-menu-visible': showNavigationMenu, 'show': ['Inicio', 'Login', 'Resgatar Senha', 'Termos'].includes($route.name) || isAuthenticated }">
      <view-content>
        <router-view></router-view>
      </view-content>
    </div>
    <alerts></alerts>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Client from 'socket.io-client';
import geolocation from './geolocation';
import ViewContent from './components/ViewContent.vue';
import NavigationMenu from './components/NavigationMenu.vue';
import Alerts from './components/Alerts.vue';
import constants from './constants';

const { TripStatus } = constants;

export default {
  components: { ViewContent, NavigationMenu, Alerts },
  computed: {
    ...mapState(['map', 'displayNavigationMenu', 'showNavigationMenu', 'isAuthenticated', 'userData', 'lastTripUpdated', 'trips', 'currentTripRequest', 'currentPosition']),
    ...mapGetters(['getTripByUid']),
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal) {
        const socket = new Client(process.env.VUE_APP_NODE_URL, {
          transports: ['websocket'],
          randomizationFactor: 0,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 20000,
          reconnection: true,
          reconnectionAttempts: 999999,
        });

        socket.on('connect', () => {
          const { api_token: token, tipo } = this.userData;

          socket.emit('authentication', { api_token: token, tipo });
        });

        socket.on('connect_error', () => {
          this.$store.commit('showWarningAlert', 'Houve um erro de comunicação com o servidor');
        });

        socket.on('authenticated', () => {});

        socket.on('updateCorrida', (data) => {
          this.$store.commit('updateTrip', data);
          socket.emit('updateCorridaReceived', data);
        });

        socket.on('updateDirections', (data) => {
          console.log('updateDirections'); // TODO handle
          console.log(data);
        });

        socket.on('updateTimeDirections', (data) => {
          console.log('updateTimeDirections'); // TODO handle
          console.log(data);
        });

        socket.on('setCorridas', (data) => {
          this.setCorridas(data);
        });

        socket.on('stopCallDriver', (data) => {
          this.stopCallDriver(data);
        });

        socket.on('restartCallDriver', (data) => {
          this.$store.commit('setBlockRequest', true);

          this.restartCallDriver(data);
        });
      }
    },
    lastTripUpdated(newVal) {
      if (newVal.status > TripStatus.SOLICITANDO) {
        localStorage.removeItem('currentTripRequest');
      }

      if (newVal.status > TripStatus.MOTORISTA_DESLOCAMENTO) {
        this.$store.commit('removeTrip', newVal);
      }

      if (newVal.status === TripStatus.CANCELADA_MOTORISTA) {
        this.$store.commit('showWarningAlert', 'Garupa cancelada pelo motorista');
      } else if (newVal.status === TripStatus.CANCELADA_PASSAGEIRO) {
        this.$store.commit('showSuccessAlert', 'Garupa cancelada');
      } else if (newVal.status === TripStatus.MOTORISTA_DESLOCAMENTO) {
        this.$store.commit('showSuccessAlert', 'Chamada aceita, motorista em deslocamento');
      }
    },
    trips(newVal) {
      const tripRequest = (newVal || []).filter((value) => (value.status === TripStatus.SOLICITANDO))[0];

      if (tripRequest) {
        this.$store.commit('setCurrentTripRequest', tripRequest);
      } else if (this.currentTripRequest) {
        this.$store.commit('setCurrentTripRequest', null);
        this.$store.commit('setBlockRequest', false);
      }
    },
  },
  methods: {
    updateViewLeaflet(newCurrentLocation) {
      if (Math.abs(this.currentPosition.lat < newCurrentLocation.lat) >= 1 && this.map) {
        this.map.setView([newCurrentLocation.lat, newCurrentLocation.lng]);
      }
    },
    setCorridas(trips) {
      this.$store.commit('setTrips', trips);

      const currentTripRequest = JSON.parse(localStorage.getItem('currentTripRequest'));

      if (currentTripRequest) {
        const trip = trips.filter((value) => (value.uid === currentTripRequest.uid))[0];

        if (trip) {
          if (trip.status > TripStatus.SOLICITANDO) {
            this.$store.commit('showSuccessAlert', 'Chamada aceita, motorista em deslocamento');
            localStorage.removeItem('currentTripRequest');
          }

          return;
        }

        this.$store.commit('removeTrip', currentTripRequest);
      }

      const tripRequest = (trips || []).filter((value) => (value.status === TripStatus.SOLICITANDO))[0];

      if (tripRequest) {
        this.$store.commit('showInfoAlert', 'Chamada em andamento...');

        this.$store.commit('setBlockRequest', true);

        localStorage.setItem('currentTripRequest', JSON.stringify({
          ...this.currentTripRequest,
          expiresAt: Date.now() + 180000,
        }));
      }
    },
    stopCallDriver(trip) {
      this.$store.commit('showErrorAlert', 'Nenhum motorista disponível');
      this.$store.commit('removeTrip', trip);
      localStorage.removeItem('currentTripRequest');
    },
    restartCallDriver(trip) {
      const tripRequest = {
        uid: trip.uid,
        status: TripStatus.SOLICITANDO,
        expiresAt: Date.now() + trip.expiresIn,
        origin: trip.origin,
      };

      if (this.currentTripRequest) {
        this.$store.commit('removeTrip', this.currentTripRequest);
      }

      this.$store.commit('showInfoAlert', 'Procurando um novo motorista...');
      this.$store.commit('setTrip', tripRequest);

      localStorage.setItem('currentTripRequest', JSON.stringify(tripRequest));
    },
    restoreTripRequest() {
      const tripRequest = JSON.parse(localStorage.getItem('currentTripRequest'));

      if (tripRequest) {
        if (tripRequest.expiresAt > Date.now()) {
          this.$store.commit('setTrip', tripRequest);
        } else {
          localStorage.removeItem('currentTripRequest');
        }
      }
    },
  },
  created() {
    this.restoreTripRequest();

    const storage = localStorage.getItem('settings');
    const settings = JSON.parse(storage) || {};

    settings.lat_localizacao_atual = settings.lat_localizacao_atual ? parseFloat(settings.lat_localizacao_atual) : null;
    settings.lng_localizacao_atual = settings.lng_localizacao_atual ? parseFloat(settings.lng_localizacao_atual) : null;

    this.$store.commit('setSettings', settings);

    const { lat_localizacao_atual: lat, lng_localizacao_atual: lng } = settings;

    if (lat && lng) {
      this.$store.commit('setCurrentPosition', { lat, lng });
    } else {
      const id = geolocation.watchPosition(
        (location) => {
          this.updateViewLeaflet(location);
          this.$store.commit('setCurrentPosition', location);
        },
        () => (this.$store.commit('setShowGeolocationMessage', true)),
      );

      this.$store.commit('setGeolocationWatchId', id);
    }
  },
};
</script>

<style scoped lang="scss">
  #app {
    overflow: hidden;
    height: 100vh;
    background-color: #25A58C;
  }

  #garupa {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    padding: 35px;

    img {
      display: block;
      margin: auto;
      width: 100%;
      max-width: 300px;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  #container {
    height: 100vh;
    width: 100%;
    box-shadow: 4px 0px 10px #000;
    display: none;

    &.show {
      display: block;
    }

    &.side-menu {
      transform: translate3d(0px, 0px, 0px);
      transition: transform 250ms;
    }

    &.side-menu-visible {
      transform: translate3d(265px, 0px, 0px) !important;
    }
  }
</style>

<style lang="scss">
  @import '@/assets/sass/reset.scss';
  @import '@/assets/sass/common.scss';
</style>
