<template>
  <div id="geolocation-modal" class="modal">
    <div class="header">
      <span class="text">Geolocalização</span>
      <button class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </button>
    </div>
    <div class="body">
      <div class="container">
        <div id="leaflet-map">
          <div id="mapContainer" ref="mapContainer"></div>
        </div>
        <div id="search-box" :class="{ 'fill': !isPinMode }">
          <div id="content">
            <input type="text" class="input-text" v-model="geolocation.address" @keyup="lazySearch" @focus="isPinMode = false" maxlength="100">
            <div v-show="!isPinMode">
              <div id="pin">
                <span @click="isPinMode = true">MARCAR LOCALIZAÇÃO NO MAPA</span>
              </div>
              <div id="places">
                <ul v-if="!isSearchingPlace">
                  <li v-for="place in places" :key="place.placeID" @click="selectAddress(place)">{{ place.description }}</li>
                </ul>
                <ul v-if="isSearchingPlace">
                  <li>Pesquisando endereços...</li>
                </ul>
                <ul v-if="placeNotFound">
                  <li>Nenhum resultado encontrato</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="marker">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336,96a80,80,0,1,0-96,78.39V457.56a32.09,32.09,0,0,0,2.49,12.38l10.07,24a3.92,3.92,0,0,0,6.88,0l10.07-24A32.09,32.09,0,0,0,272,457.56V174.39A80.13,80.13,0,0,0,336,96Zm-56,0a24,24,0,1,1,24-24A24,24,0,0,1,280,96Z"/></svg>
        </div>
        <ajax-button id="btn-price" class="button button-primary" @click.native="confirmGeolocation" :is-disabled="isSearchingAddress">Confirmar</ajax-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import garupa from '../api/garupa';
import AjaxButton from './AjaxButton.vue';
import mapStyles from '../utils/maps';

const { createLeafletMap } = mapStyles;

export default {
  components: { AjaxButton },
  props: {
    lat: Number,
    lng: Number,
  },
  data() {
    return {
      map: null,
      mapCenter: null,
      geolocation: {
        address: '',
      },
      places: [],
      isPinMode: true,
      canReverseGeocode: true,
      isSearchingAddress: false,
      isSearchingPlace: false,
      placeNotFound: false,
    };
  },
  computed: mapState(['currentPosition']),
  watch: {
    async mapCenter(newPosition) {
      if (this.isPinMode && this.canReverseGeocode) {
        const { lat, lng } = newPosition;
        const address = await this.getAddress(lat, lng);

        this.setGeolocation(lat, lng, address);
      }

      this.canReverseGeocode = true;
    },
  },
  methods: {
    formatAddress(address = {}) {
      const { rua, numero, cidade, uf } = address;

      return `${rua}, ${numero}. ${cidade}/${uf}`;
    },
    setupLeafletMap() {
      this.map = createLeafletMap(this.$refs.mapContainer, this.currentPosition);
    },
    setupObservers() {
      this.setupLeafletMap();

      const myZoom = {
        start: this.map.getZoom(),
        end: this.map.getZoom(),
      };

      this.map.on('dragend', () => {
        this.mapCenter = { lat: this.map.getCenter().lat, lng: this.map.getCenter().lng };
      });

      this.map.on('zoomend', () => {
        this.$store.commit('setMapCenter', { lat: this.map.getCenter().lat, lng: this.map.getCenter().lng });
        myZoom.end = this.map.getZoom();
      });
    },
    async getAddress(lat, lng) {
      this.isSearchingAddress = true;

      const { error, result } = await garupa.reverseGeocode(lat, lng);

      this.isSearchingAddress = false;

      if (result) {
        return typeof result === 'string' ? result : this.formatAddress(result);
      }

      this.$store.commit('showErrorAlert', error);

      return null;
    },
    async search() {
      this.isSearchingPlace = true;
      this.placeNotFound = false;

      const { error, result } = await garupa.places(
        this.currentPosition.lat,
        this.currentPosition.lng,
        this.geolocation.address,
      );

      this.isSearchingPlace = false;

      if (result) {
        this.places = result;

        if (_.isEmpty(result)) {
          this.placeNotFound = true;
        }
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
    selectAddress(place) {
      const address = place.description;
      const { lat, lon: lng } = place.coords;

      this.canReverseGeocode = false;
      this.isPinMode = true;
      this.map.setView([lat, lng]);

      this.setGeolocation(lat, lng, address);
    },
    close() {
      this.$emit('close');
    },
    setGeolocation(lat, lng, address) {
      this.geolocation = { lat, lng, address };
    },
    confirmGeolocation() {
      this.$emit('close', this.geolocation);
    },
  },
  async created() {
    this.lazySearch = _.debounce(this.search, 1000);
    let { lat, lng } = this.currentPosition;
    if (this.lat && this.lng) {
      lat = this.lat;
      lng = this.lng;
    }
    const address = await this.getAddress(lat, lng);

    this.setGeolocation(lat, lng, address);
  },
  mounted() {
    this.setupObservers();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/sass/componentsLayout/GeolocationModal.scss';
</style>
