<template>
  <div id="gerenciar-usuarios" class="center">
    <form @submit.prevent="buscaUsuarios">
      <div class="row">
        <table>
          <tr>
            <td>
              <div class="form-field">
              <label for="nome">Nome</label>
              <input id="nome" type="text" v-model="filtro.nome" maxlength="50">
              </div>
            </td>
            <td>
              <div class="form-field">
              <label for="email">E-mail</label>
              <input id="email" type="text" v-model="filtro.email" maxlength="40">
              </div>
            </td>
            <td>
              <div class="form-field">
                <ajax-button class="button button-primary" type="submit" style="margin-top: 40px;">Pesquisar</ajax-button>
              </div>
            </td>
        </tr>
        </table>
      </div>
    </form>
    <loader v-if="isLoading"></loader>
    <table v-else>
      <thead>
        <tr>
          <th>#</th>
          <th>Nome</th>
          <th>Sobrenome</th>
          <th>E-mail</th>
          <th>Situação</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!users.length">
          <td colspan="6" class="text-center">Nenhum registro encontrado</td>
        </tr>
        <tr v-for="(user, index) in users" :key="user.uid">
          <td class="number">{{ index + 1 }}</td>
          <td>{{ user.nome }}</td>
          <td>{{ user.sobrenome }}</td>
          <td>{{ user.email }}</td>
          <td class="status" :class="{ 'active': user.situacao === 1, 'inactive': user.situacao === 0 }">
            {{ user.situacao | formatUserStatus }}
          </td>
          <td v-if="user.tipo != 2" class="actions" width="90">
            <dropdown-menu right="0" width="285px">
              <template v-slot:button>
                <div class="button button-primary">
                  <span>Ações</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
                  </svg>
                </div>
              </template>
              <template v-slot:menu>
                <ul>
                  <li @click="openChangePasswordModal(user)">Alterar Senha</li>
                  <li @click="changeUserStatus(user, 1)" v-if="user.situacao === 0">Ativar</li>
                  <li @click="changeUserStatus(user, 0)" v-else-if="user.situacao === 1">Inativar</li>
                </ul>
              </template>
            </dropdown-menu>
          </td>
          <td v-else class="actions" width="90">
            <button class="button button-primary" style="background-color: #bcbcbc;" disabled>
              <span>Ações</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"/>
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import garupa from '../api/garupa';
import AjaxButton from '../components/AjaxButton.vue';
import Loader from '../components/Loader.vue';
import ConfirmationModal from '../components/ConfirmationModal.vue';
import ChangePasswordModal from '../components/ChangePasswordModal.vue';
import ManagePaymentTypeModal from '../components/ManagePaymentTypeModal.vue';
import DropdownMenu from '../components/DropdownMenu.vue';

export default {
  components: { DropdownMenu, Loader, AjaxButton },
  data() {
    return {
      isLoading: false,
      users: [],
      filtro: {
        nome: '',
        email: '',
      },
    };
  },
  computed: mapState(['userData']),
  methods: {
    async buscaUsuarios() {
      this.isLoading = true;
      const { error, result } = await garupa.listarUsuarios(this.filtro.nome, this.filtro.email, this.userData.tipo);

      this.isLoading = false;

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        this.users = result || [];
      }
    },
    changeUserStatus(user, status) {
      this.$modal.show(ConfirmationModal, {
        title: `Deseja ${status ? 'ativar' : 'inativar'} esse usuário?`,
      }, {
        width: '350px',
        height: 'auto',
      }, {
        'before-close': (event) => {
          if (event.params) {
            this.changeStatus(user);
          }
        },
      });
    },
    openChangePasswordModal(user) {
      this.$modal.show(ChangePasswordModal, {
        user,
      }, {
        width: '600px',
        height: 'auto',
      });
    },
    openManagePaymentTypeModal(user) {
      this.$modal.show(ManagePaymentTypeModal, {
        user,
      }, {
        width: '800px',
        height: 'auto',
      });
    },
    async changeStatus(user) {
      this.isLoading = true;

      const { error, result } = await garupa.alterarSituacao(user.uid);

      this.isLoading = false;

      if (result) {
        this.$store.commit('showSuccessAlert', 'Situação alterada com sucesso');

        this.users = _.map(this.users, (value) => {
          const val = value;

          if (value.uid === user.uid) {
            val.situacao = +!val.situacao;
          }

          return val;
        });
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
  },
  async created() {
    this.buscaUsuarios();
  },
};
</script>

<style scoped lang="scss">
#gerenciar-usuarios {
    height: 100%;
    width: 980px;
  }

  table {
    .text-center {
      text-align: center;
    }

    .status {
      &.active {
        color: #25a58c;
      }

      &.inactive {
        color: #d44161;
      }
    }

    .actions,
    .number {
      word-break: normal;
    }

    .actions {
      float: right;

      .button {
        display: flex;
        border-radius: 5px;
        min-height: 35px;
        padding-right: 8px;

        span {
          display: block;
          align-self: center;
          font-weight: normal;
          line-height: 20px;
          margin-right: 5px;
        }

        svg {
          width: 18px;
          display: block;
          align-self: center;
        }
      }
    }
  }
     form {
       display: flex;
       flex-flow: column;
       .picture {
         margin-bottom: 15px;
         label {
           display: flex;
           flex-direction: column;
           cursor: pointer;
           .image-wrapper {
             width: 250px;
             height: 250px;
             border-radius: 50%;
             align-self: center;
             overflow: hidden;
             background-color: #eee;
             img {
               display: block;
               width: 100%;
               height: 100%;
             }
           }
           input[type=file] {
             display: none;
           }
         }
         span {
           display: block;
           text-align: center;
           color: #333;
           line-height: 22px;
           margin-top: 10px;
         }
       }
       .button {
         width: 100%;
         max-width: 360px;
         margin: auto;
       }
     }
</style>
