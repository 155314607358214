import Vue from 'vue';
import VueResource from 'vue-resource';
import VModal from 'vue-js-modal';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rotatedmarker/leaflet.rotatedMarker';

Vue.use(VueResource);
Vue.use(VModal, {
  dynamicDefaults: {
    adaptive: true,
  },
});
Vue.http.options.root = process.env.VUE_APP_API_URL;
Vue.http.options.timeout = 30000;

Vue.http.interceptors.push((request) => {
  const { userData } = store.state;

  if (userData && userData.api_token) {
    request.headers.set('Authorization', `Bearer ${userData.api_token}`);
  }

  return (response) => {
    if (response.status === 401) {
      localStorage.clear();
      router.go();
    }
  };
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
