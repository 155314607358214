<template>
  <div id="alert-message">
    <div class="message-content" :class="[alert.type]" @click="removeAlert">
      <div class="icon">
        <svg class="info" v-if="alert.type === alertTypes.INFO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,56C145.72,56,56,145.72,56,256s89.72,200,200,200,200-89.72,200-200S366.28,56,256,56Zm0,82a26,26,0,1,1-26,26A26,26,0,0,1,256,138Zm48,226H216a16,16,0,0,1,0-32h28V244H228a16,16,0,0,1,0-32h32a16,16,0,0,1,16,16V332h28a16,16,0,0,1,0,32Z"/></svg>
        <svg class="error" v-if="alert.type === alertTypes.ERROR" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm75.31,260.69a16,16,0,1,1-22.62,22.62L256,278.63l-52.69,52.68a16,16,0,0,1-22.62-22.62L233.37,256l-52.68-52.69a16,16,0,0,1,22.62-22.62L256,233.37l52.69-52.68a16,16,0,0,1,22.62,22.62L278.63,256Z"/></svg>
        <svg class="success" v-if="alert.type === alertTypes.SUCCESS" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z"/></svg>
        <svg class="warning" v-if="alert.type === alertTypes.WARNING" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm0,319.91a20,20,0,1,1,20-20A20,20,0,0,1,256,367.91Zm21.72-201.15-5.74,122a16,16,0,0,1-32,0l-5.74-121.94v-.05a21.74,21.74,0,1,1,43.44,0Z"/></svg>
      </div>
      <span class="text">{{ this.alert.message }}</span>
      <svg class="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><line x1="368" y1="368" x2="144" y2="144" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="368" y1="144" x2="144" y2="368" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import constants from '../constants';

const { AlertTypes } = constants;

export default {
  props: ['alert'],
  data() {
    return {
      timerId: null,
    };
  },
  computed: {
    alertTypes() {
      return AlertTypes;
    },
  },
  methods: {
    removeAlert() {
      clearTimeout(this.timerId);
      this.$store.commit('removeAlert', this.alert.id);
    },
  },
  created() {
    this.timerId = _.delay(() => {
      this.removeAlert();
    }, 15000);
  },
};
</script>

<style scoped lang="scss">
  #alert-message {
    display: flex;
    justify-content: flex-end;

    .message-content {
      padding: 10px 12px 10px 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 5px -2px #333;
      background-color: rgba(255, 255, 255, 0.95);
      cursor: default;

      .icon {
        margin-right: 20px;

        svg {
          width: 30px;
          display: block;
        }

        .info {
          fill: #1cadf2;
        }

        .error {
          fill: #f56c6c;
        }

        .success {
          fill: #17b77e;
        }

        .warning {
          fill: #ffc603;
        }
      }

      .text {
        color: #333;
        line-height: 20px;
        margin-right: 15px;
        display: block;
      }

      .close {
        width: 22px;
        stroke: #333;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }
</style>
