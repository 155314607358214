<template>
  <div id="chamar-motorista">
    <ajax-button id="btn-cancel-trip" class="button button-primary" :is-disabled="isCancelingRequest" @click.native="cancelTrip">Cancelar Garupa</ajax-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import L from 'leaflet';
import constants from '../constants';
import garupa from '../api/garupa';
import AjaxButton from '../components/AjaxButton.vue';

const { TripStatus } = constants;

export default {
  components: { AjaxButton },
  data() {
    return {
      isCancelingRequest: false,
    };
  },
  computed: {
    ...mapState(['map', 'markers', 'origin', 'currentPosition', 'trips', 'currentTripRequest']),
  },
  watch: {
    trips: {
      handler(newVal) {
        const tripRequest = (newVal || []).filter((trip) => (trip.status === TripStatus.SOLICITANDO))[0];

        if (!tripRequest) {
          localStorage.removeItem('currentTripRequest');
          this.$router.push('/chamar');
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async cancelTrip() {
      this.isCancelingRequest = true;

      const { error, result } = await garupa.cancelarSolicitacao(this.currentTripRequest.uid);

      this.isCancelingRequest = false;

      if (result) {
        garupa.dismiss(this.currentTripRequest.uid);

        this.$store.commit('showSuccessAlert', 'Garupa cancelada');
        this.$store.commit('removeTrip', this.currentTripRequest);
        localStorage.removeItem('currentTripRequest');
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
    setRipple(lat, lng) {
      const rippleIcon = L.icon({
        iconUrl: require('@/assets/svg/ripple.svg'),
        iconSize: [200, 200],
      });
      this.markers[1] = L.marker([lat, lng], {
        icon: rippleIcon,
      }).addTo(this.map);
    },
  },
  created() {
    const origin = this.origin || (JSON.parse(localStorage.getItem('currentTripRequest')) || {}).origin;

    if (origin) {
      _.delay(() => {
        this.setRipple(origin.lat, origin.lng);
        this.map.setView([origin.lat, origin.lng]);
      }, 500);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.currentTripRequest && this.map) {
      this.map.removeLayer(this.markers[1]);
    }
    next();
  },
};
</script>

<style scoped lang="scss">
  #btn-cancel-trip {
    position: absolute;
    bottom: 35px;
    width: 100%;
    max-width: 300px;
    left: 50%;
    z-index: 15;
    transform: translate(-50%, 0);
    box-shadow: 0 0 8px -5px #333;
  }
</style>
