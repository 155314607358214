<template>
  <div id="dropdown-menu" v-click-outside="close">
    <button class="toggle" @click="toggleMenu">
      <slot name="button"></slot>
    </button>
    <div class="menu" :style="{ 'left': left, 'right': right, 'width': width }" v-show="this.showMenu" @click="close">
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: { ClickOutside },
  props: {
    width: {
      type: String,
      default: '150px',
    },
    right: {
      type: String,
      default: 'auto',
    },
    left: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    close() {
      this.showMenu = false;
    },
  },
};
</script>

<style scoped lang="scss">
  #dropdown-menu {
    position: relative;

    .toggle {
      display: block;
      margin: 0;
      padding: 0;
      background-color: transparent;
      outline: 0;
      border: none;
    }

    .menu {
      position: absolute;
      width: 150px;
      text-align: left;
      z-index: 10;
      box-shadow: 0px 0px 6px -4px #333;
      bottom: -2px;
      transform: translateY(100%);

      ul {
        background-color: #fff;
        border: 1px solid #eee;
        padding: 8px 0;
        border-radius: 3px;

        li {
          cursor: pointer;
          padding: 8px 22px;
          line-height: 20px;

          &:hover,
          &:focus {
            background-color: #eee;
          }
        }
      }
    }
  }
</style>
