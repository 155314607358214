<template>
  <div id="confirm-phone-number-modal">
    <span class="title">Confirme o seu telefone</span>
    <span class="text">Informe o telefone que você vai usar para fazer a ligação</span>
    <input class="phone-number" type="tel" v-model="phoneNumber" :disabled="isLoading" placeholder="Ex: (51) 99999-9999" maxlength="12">
    <div class="buttons">
      <button class="cancel button" @click="close">Cancelar</button>
      <ajax-button class="confirm button button-primary" :is-disabled="isLoading" @click.native="confirm">OK</ajax-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import garupa from '../api/garupa';
import AjaxButton from './AjaxButton.vue';

export default {
  components: { AjaxButton },
  props: {
    trip: Object,
  },
  data() {
    return {
      phoneNumber: '',
      isLoading: false,
    };
  },
  computed: mapState(['trips']),
  watch: {
    trips: {
      handler(newVal) {
        const trip = newVal.filter((value) => (value.uid === this.trip.uid))[0];

        if (!trip) {
          this.close();
        }
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async confirm() {
      if (!this.phoneNumber.trim().length) {
        return;
      }

      this.isLoading = true;

      const { error, result } = await garupa.makePhoneCall(this.trip.uid, this.phoneNumber);

      this.isLoading = false;

      if (error) {
        this.$store.commit('showErrorAlert', error);
      } else {
        window.open(`tel:${result}`);
      }

      localStorage.setItem('phoneNumber', this.phoneNumber);

      this.$emit('close');
    },
  },
  async created() {
    this.isLoading = true;

    const { error, result } = await garupa.canMakePhoneCall();

    if (error) {
      this.$store.commit('showErrorAlert', error);
    } else if (result) {
      this.isLoading = false;

      this.phoneNumber = localStorage.getItem('phoneNumber') || '';
    }
  },
};
</script>

<style scoped lang="scss">
  #confirm-phone-number-modal {
    padding: 20px;
    background-color: #eee;
    height: 100%;
    color: #333;

    .title {
      display: block;
      font-weight: bold;
      text-align: center;
      line-height: 18px;
      font-size: 17px;
      margin-bottom: 10px;
    }

    .text {
      display: block;
      text-align: center;
      line-height: 18px;
      margin-bottom: 15px;
    }

    .phone-number {
      -moz-appearance: textfield;
      font-family: inherit;
      font-size: 16px;
      width: 100%;
      outline: 0;
      padding: 10px;
      margin: 0;
      border: none;
      line-height: 20px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 2px;

      &:disabled {
        background-color: #fff;
        color: #ccc;
      }
    }

    .phone-number::-webkit-outer-spin-button,
    .phone-number::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        border-radius: 2px;
      }

      .cancel {
        color: #333;
        flex-grow: 1;
      }

      .confirm {
        flex-grow: 2;
        margin-left: 10px;
      }
    }
  }
</style>
