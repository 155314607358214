<template>
  <div id="historico">
    <loader v-if="isLoading"></loader>
    <ul v-else class="center">
      <li v-for="item in items" :key="item.uid" class="list-item">
        <div class="top-content">
          <div class="driver-info">
            <img :src="item.foto_motorista">
            <div class="info">
              <div class="driver">
                <span class="name">{{ item.nome_motorista }}</span>
                <div class="favorite" v-if="item.uid_motorista_favorito" title="Favorito">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"/>
                  </svg>
                </div>
              </div>
              <span class="vehicle">{{ item.marca }} {{ item.modelo }} {{ item.placa.toUpperCase() }}</span>
            </div>
          </div>
          <div class="options">
            <dropdown-menu right="0" width="215px">
              <template v-slot:button>
                <div class="action">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <circle cx="256" cy="256" r="32" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><circle cx="256" cy="416" r="32" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><circle cx="256" cy="96" r="32" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/>
                  </svg>
                </div>
              </template>
              <template v-slot:menu>
                <ul>
                  <li v-if="item.uid_motorista_favorito" @click="removeFavorite(item)">Desfavoritar Motorista</li>
                  <li v-else @click="addFavorite(item)">Favoritar Motorista</li>
                </ul>
              </template>
            </dropdown-menu>
          </div>
        </div>
        <div class="trip-info">
          <div class="info">
            <span class="date">{{ item.dt_fim | formatDateTime }}</span> <br>
            <span class="price" v-if="item.valor_realizado"> Valor Realizado: {{ item.valor_realizado | formatPrice }} </span>
          </div>
          <span class="status" v-if="item.status === tripStatus.CANCELADA_PASSAGEIRO">Cancelada pelo passageiro</span>
          <div class="origin">
            Início: <span>{{ item.endereco_partida }}</span>
          </div>
          <div class="destination" v-if="item.endereco_final">
            Fim: <span>{{ item.endereco_final }}</span>
          </div>
          <span class="distance-duration">{{ item.distancia_realizada | formatDistance }} - {{ item.duracao_realizada | formatDuration }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import garupa from '../api/garupa';
import constants from '../constants';
import DropdownMenu from '../components/DropdownMenu.vue';
import Loader from '../components/Loader.vue';
import store from '../store/index';

const { TripStatus } = constants;

export default {
  components: { Loader, DropdownMenu },
  filters: {
    formatDistance(distance = 0) {
      return distance < 1 ? `${distance * 1000} m` : `${distance.toFixed(1)} Km`;
    },
    formatDuration(duration = 0) {
      return duration < 1 ? `${Math.round(duration * 60)} seg` : `${Math.round(duration)} min`;
    },
  },
  data() {
    return {
      items: null,
    };
  },
  computed: {
    isLoading() {
      return !this.items;
    },
    tripStatus() {
      return TripStatus;
    },
  },
  methods: {
    async addFavorite(item) {
      this.items = null;

      const { error } = await garupa.addFavorite(item.uid_motorista);

      if (error) {
        store.commit('showErrorAlert', error);
      }

      this.loadHistory();
    },
    async removeFavorite(item) {
      this.items = null;

      const { error } = await garupa.removeFavorite(item.uid_motorista);

      if (error) {
        store.commit('showErrorAlert', error);
      }

      this.loadHistory();
    },
    async loadHistory() {
      const { error, result } = await garupa.historico();
      if (error) {
        store.commit('showErrorAlert', error);
      } else {
        this.setItems(result);
      }
    },
    setItems(items) {
      this.items = items;
    },
  },
  async created() {
    await this.loadHistory();
  },
};
</script>

<style scoped lang="scss">
  #historico {
    background-color: #eee;
    height: 100%;
    overflow: auto;

    ul {
      padding-top: 15px;
      padding-bottom: 15px;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .top-content {
          display: flex;
          margin-bottom: 10px;

          .driver-info {
            display: flex;
            flex-grow: 1;

            img {
              display: block;
              border-radius: 2px;
              width: 50px;
              height: 50px;
            }

            .info {
              margin-left: 10px;

              .driver {
                display: flex;

                .favorite {
                  margin-left: 10px;
                  align-self: center;

                  svg {
                    display: block;
                    width: 20px;
                    fill: #25A58C;
                  }
                }
              }

              .vehicle {
                color: #777;
                display: block;
              }
            }
          }

          .options {
            .action {
              display: block;
              cursor: pointer;

              svg {
                display: block;
                width: 25px;
                color: #333;
              }
            }
          }
        }

        .trip-info {
          line-height: 25px;

          .info {
            font-weight: bold;
            margin-bottom: 12px;

            .date {

            }

            .price {

            }
          }

          .status {
            font-weight: bold;
            display: block;
            margin-bottom: 12px;
          }

          .origin {
            font-weight: bold;
            color: #25A58C;
            margin-bottom: 12px;

            span {
              font-weight: normal;
            }
          }

          .destination {
            font-weight: bold;
            color: #db4d6c;
            margin-bottom: 12px;

            span {
              font-weight: normal;
            }
          }

          .distance-duration {

          }
        }
      }
    }
  }
</style>
