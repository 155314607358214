<template>
  <div id="paginator-view" ref="paginatorView">
    <slot></slot>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    offsetBottom: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      canEmit: false,
      currentHeight: null,
    };
  },
  mounted() {
    this.$refs.paginatorView.addEventListener('scroll', _.throttle((e) => {
      if (this.currentHeight !== e.target.scrollHeight) {
        this.currentHeight = e.target.scrollHeight;
        this.canEmit = true;
      }

      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - this.offsetBottom && this.canEmit) {
        this.canEmit = false;
        this.$emit('paginate');
      }
    }, 500));
  },
};
</script>

<style scoped lang="scss">
  #paginator-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
</style>
