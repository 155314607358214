<template>
  <div id="alerts">
      <transition-group name="alert" appear>
        <div v-for="alert in alerts" :key="alert.id">
            <alert-message :alert="alert"></alert-message>
        </div>
      </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AlertMessage from './AlertMessage.vue';

export default {
  components: { AlertMessage },
  computed: mapState(['alerts']),
};
</script>

<style scoped lang="scss">
  #alerts {
    position: absolute;
    top: 55px;
    margin-left: 25px;
    right: 25px;
    z-index: 1000;
  }

  .alert-enter {
    opacity: 0;
  }

  .alert-enter-active {
    transition: opacity 250ms ease;
  }

  .alert-leave-active {
    transition: opacity 150ms ease;
    opacity: 0;
  }
</style>
