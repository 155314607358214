<template>
  <div id="termos">
    <loader v-if="isLoading"></loader>
    <div v-else class="center" v-html="termos"></div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
import garupa from '../api/garupa';

export default {
  components: { Loader },
  data() {
    return {
      termos: '',
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;

    const { error, body } = await garupa.termosDeUso();

    this.isLoading = false;

    if (body) {
      this.termos = body;
    } else {
      this.$store.commit('showErrorAlert', error);
    }
  },
};
</script>

<style scoped lang="scss">
  #termos {
    height: 100%;
  }
</style>
