<template>
  <div id="save-update-email-modal" class="modal">
    <div class="header">
      <span class="text">{{item ? 'Editar' : 'Novo'}} E-mail</span>
      <button class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </button>
    </div>
    <div class="body">
      <form @submit.prevent="save">
        <div class="form-field">
          <label for="name">Nome</label>
          <input id="name" class="text" type="text" v-model="name" :disabled="isLoading" maxlength="50" ref="name">
          <span class="error" v-if="submitted && !name.trim()">Campo obrigatório</span>
        </div>
        <div class="form-field">
          <label for="email">E-mail</label>
          <input id="email" class="text" type="text" v-model="email" :disabled="isLoading" maxlength="40">
          <span class="error" v-if="submitted && !email.trim()">Campo obrigatório</span>
        </div>
        <div class="form-field">
          <label for="description">Observação</label>
          <textarea id="description" class="description" rows="5" v-model="description" :disabled="isLoading" maxlength="100"></textarea>
          <span class="error" v-if="submitted && !description.trim()">Campo obrigatório</span>
        </div>
        <div class="form-field">
          <label for="default">
            <input id="default" class="text" type="checkbox" v-model="defaultAlterado" :disabled="defaultAlterado && defaultAnterior" size="lg">
            Endereço de Cobrança Principal
          </label>
        </div>
        <ajax-button class="button button-primary" type="submit" :is-disabled="isLoading">Salvar</ajax-button>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import garupa from '../api/garupa';
import AjaxButton from './AjaxButton.vue';

export default {
  components: { AjaxButton },
  props: {
    item: Object,
    uidEmpresa: String,
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      uid: null,
      name: '',
      email: '',
      description: '',
      defaultAlterado: false,
      defaultAnterior: false,
    };
  },
  methods: {
    async save() {
      this.submitted = true;

      if (this.email.trim().search(/^adm[a-z0-9ç\-_]*@garupa\.co$/i) !== -1) {
        this.$store.commit('showWarningAlert', 'E-mail inválido para cadastro');
        return;
      }

      if (!this.name.trim() || !this.email.trim() || !this.description.trim()) {
        return;
      }

      this.isLoading = true;

      const { error, result } = await garupa.salvarEmailFatura(this.uid, this.name, this.email, this.description, this.defaultAlterado, this.defaultAnterior, this.uidEmpresa);

      this.isLoading = false;

      if (result) {
        this.$store.commit('showSuccessAlert', 'E-mail de fatura salvo com sucesso');
        this.$emit('close', true);
      } else if (error.errors) {
        _.forEach(error.errors, (value) => {
          this.$store.commit('showWarningAlert', value[0]);
        });
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    if (this.item) {
      this.uid = this.item.uid;
      this.name = this.item.nome;
      this.email = this.item.email;
      this.description = this.item.observacao;
      this.defaultAlterado = this.item.default;
      this.defaultAnterior = this.item.default;
    }
  },
  mounted() {
    this.$refs.name.focus();
  },
};
</script>

<style scoped lang="scss">
  form {
    display: flex;
    flex-direction: column;
    padding: 30px;

    .button {
      width: 100%;
      max-width: 360px;
      margin: auto;
    }

    .description {
      border-radius: 25px;
    }
  }
</style>
