<template>
  <div id="chat-modal" class="modal">
    <div class="header">
      <span class="text">Chat Garupa</span>
      <button class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </button>
    </div>
    <div class="body">
      <chat-modal-messages :messages="messages" :showLoader="isLoading"></chat-modal-messages>
    </div>
    <div class="footer">
      <form @submit.prevent="sendMessage">
        <input class="text" type="text" v-model="text" maxlength="100" ref="text">
        <input class="button button-primary" type="submit" value="Enviar">
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import firebase from '../api/firebase';
import ChatModalMessages from './ChatModalMessages.vue';

export default {
  components: { ChatModalMessages },
  props: {
    trip: Object,
  },
  data() {
    return {
      text: '',
      messages: [],
      isLoading: false,
    };
  },
  watch: {
    trips: {
      handler(newVal) {
        const trip = newVal.filter((value) => (value.uid === this.trip.uid))[0];

        if (!trip) {
          this.close();
        }
      },
      deep: true,
    },
  },
  computed: mapState(['trips']),
  methods: {
    sendMessage() {
      const text = this.text.trim();

      if (!text.length) {
        return;
      }

      this.isLoading = true;

      firebase.talk.database().ref(this.trip.uid).child('ms').push({
        m: text,
        t: 2,
      });

      this.isLoading = false;
      this.text = '';
    },
    close() {
      this.$emit('close');
    },
  },
  created() {
    this.isLoading = true;

    firebase.talk.database().ref(this.trip.uid).on('value', (snapshot) => {
      this.isLoading = false;

      const val = snapshot.val() || {};

      if (val.ms) {
        this.messages = _
          .chain(val.ms)
          .transform((result, value, key) => {
            result.push({
              key,
              message: value.m,
              timestamp: value.d || Date.now(),
              type: value.t,
              name: value.t === 1 ? val.m.n : '',
              picture: value.t === 1 ? val.m.f : '',
            });
          }, [])
          .sortBy('timestamp').value();
      }
    });
  },
  mounted() {
    this.$refs.text.focus();
  },
};
</script>

<style scoped lang="scss">
  #chat-modal {
    display: flex;
    flex-direction: column;
    height: 100%;

    .body {
      flex-grow: 1;
      position: relative;
    }

    .footer {
      padding: 10px;

      form {
        display: flex;

        .text {
          border-radius: 2px;
          margin: 0;
          flex-grow: 1;
        }

        .button {
          border-radius: 2px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 100px;
        }
      }
    }
  }
</style>
