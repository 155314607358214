import axios from 'axios';
import firebase from './firebase';
import store from '../store/index';

const Method = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  DELETE: 'delete',
};

const requestAxios = async (prefix, config) => {
  const { userData } = store.state;
  if (userData) {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${userData.api_token}` };
  }
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  try {
    const options = {
      method: Method.GET,
      url: prefix,
      ...config,
    };
    const request = await axios(options);
    const { data } = request;
    const error = data.error ? data.error : null;
    return { error, data };
  } catch (error) {
    return { error };
  }
};

const login = async (email, password) => {
  const { error, data = {} } = await requestAxios('login', {
    data: { email, password },
    method: Method.POST,
  });
  return { error, result: data.result };
};

const passwordRecovery = async (userData) => {
  const { error, data = {} } = await requestAxios('password/recovery', {
    method: Method.POST,
    data: { userData },
  });

  return { error, result: data.result };
};

const checkAuth = async () => {
  const { error, data = {} } = await requestAxios('check-auth');

  return { error, result: data.result };
};

const setFcmId = async () => {
  let token = null;

  try {
    token = await firebase.instance.messaging().getToken();
  } catch (err) {
    store.commit('showWarningAlert', 'As notificações do aplicativo estão desabilitadas');
  }

  const { error, data = {} } = await requestAxios('set-fcm-id', {
    method: Method.POST,
    data: {
      app_version: process.env.VUE_APP_VERSION,
      id: token,
    },
  });

  return { error, result: data.result };
};

const permissoes = async () => {
  const { error, data = {} } = await requestAxios('e/permissoes');

  return { error, result: data.result };
};

const reverseGeocode = async (lat, lng) => {
  const { error, data = {} } = await requestAxios('geocode/reverse-geocode', {
    method: Method.POST,
    data: { lat, lng },
  });

  return { error, result: data.result };
};

const getUidCidade = async (lat, lng) => {
  const { error, data = {} } = await requestAxios('geocode/uid-cidade', {
    method: Method.POST,
    data: { lat, lng },
  });

  return { error, result: data.result };
};

const places = async (lat, lng, q) => {
  const { error, data = {} } = await requestAxios('garupa/places', {
    method: Method.POST,
    data: { lat, lng, q },
  });

  return { error, result: data.result };
};

const estimativa = async (origin, destination) => {
  const date = new Date();
  const { error, data = {} } = await requestAxios('directions/passageiro/estimativa', {
    method: Method.POST,
    data: {
      origin,
      destination,
      dayOfWeek: date.getDay(),
      hour: date.getHours(),
    },
  });

  return { error, result: data.result };
};

const preco = async (distance = 0, duration = 0, origin, destination) => {
  const { error, data = {} } = await requestAxios('v2/corrida/preco-tipo-pagamento', {
    method: Method.POST,
    data: {
      distancia: Number((distance / 1000).toFixed(2)),
      tempo: Number((duration / 60).toFixed(2)),
      lat: origin.lat,
      lng: origin.lng,
      lat_destino: destination.lat,
      lng_destino: destination.lng,
      endereco_partida: origin.address,
      endereco_destino: destination.address,
      tipo_corrida: 1,
      perfil_corporativo: true,
    },
  });
  return { error, result: data.result };
};

const solicitar = async (distance = 0, duration = 0, origin, destination, payment, service) => {
  const { error, data = {} } = await requestAxios('corrida/solicitar', {
    method: Method.POST,
    data: {
      distancia_estimada: Number((distance / 1000).toFixed(2)),
      duracao_estimada: Number((duration / 60).toFixed(2)),
      endereco_partida: origin.address,
      endereco_destino: destination.address,
      ponto_referencia: '',
      lat_partida: origin.lat,
      lng_partida: origin.lng,
      lat_destino: destination.lat,
      lng_destino: destination.lng,
      opcional_cadeirinha: 0,
      tipo_corrida: service.type,
      uid_servicos_especializados: service.uid,
      tipo_pagamento: payment.type,
    },
  });

  return { error, result: data.result };
};

const callDriver = async (uidTrip) => {
  const { error, data = {} } = await requestAxios('call/driver', {
    method: Method.POST,
    data: {
      uid_corrida: uidTrip,
    },
  });

  return { error, result: data.result };
};

const dismiss = async (uidTrip) => (
  requestAxios('corrida/dismiss', {
    method: Method.POST,
    data: {
      uid: uidTrip,
    },
  })
);

const cancelarSolicitacao = async (uidTrip) => {
  const { error, data = {} } = await requestAxios('corrida/cancelar-solicitacao', {
    method: Method.POST,
    data: { uid: uidTrip },
  });

  return { error, result: data.result };
};

const configuracao = async () => {
  const { error, data = {} } = await requestAxios('e/configuracao');

  return { error, result: data.result };
};

const salvarConfiguracao = async (lat, lng, address, paymentType, serviceType) => {
  const { error, data = {} } = await requestAxios('e/salvar-configuracao', {
    method: Method.POST,
    data: {
      lat_localizacao_atual: lat || null,
      lng_localizacao_atual: lng || null,
      endereco_localizacao_atual: address || null,
      tipo_pagamento_preferencial: paymentType || null,
      tipo_servico_preferencial: serviceType || null,
    },
  });

  return { error, result: data.result };
};

const metodoPagamento = async (uidUser) => {
  const { error, data = {} } = await requestAxios(`e/metodo-pagamento/${uidUser}`);

  return { error, result: data.result };
};

const salvarMetodoPagamento = async (uidUser, paymentTypes) => {
  const { error, data = {} } = await requestAxios('e/salvar-metodo-pagamento', {
    method: Method.POST,
    data: {
      uid_passageiro: uidUser,
      tipos_pagamento: JSON.stringify(paymentTypes),
    },
  });

  return { error, result: data.result };
};

const criarUsuario = async (firstName, lastName, gender, email, password, picture, cpf, tipo, pais, estado, cidade, bairro, telefone, empresa) => {
  const { error, data = {} } = await requestAxios('e/criar-usuario', {
    method: Method.POST,
    data: {
      nome: firstName,
      sobrenome: lastName,
      sexo: gender,
      email,
      senha: password,
      foto: picture,
      cpf,
      tipo,
      pais,
      estado,
      cidade,
      bairro,
      telefone,
      empresa,
    },
  });

  return { error, result: data.result };
};

const listarUsuarios = async (nome, email, tipo) => {
  const { error, data = {} } = await requestAxios(`e/listar-usuarios?nome=${nome}&email=${email}&tipo=${tipo}`);

  return { error, result: data.result };
};

const listarEmpresas = async (uidUser, tipoUser) => {
  const { error, data = {} } = await requestAxios(`empresas-passageiros?uid=${uidUser}&tipo=${tipoUser}`);
  return { error, resulta: data };
};

const listarPaises = async () => {
  const { error, data = {} } = await requestAxios('paises');
  return { error, result: data };
};

const listarEstados = async (uidPais) => {
  const { error, data = {} } = await requestAxios(`estados?pais=${uidPais}`);
  return { error, result: data };
};

const listarCidades = async (uidEstado) => {
  const { error, data = {} } = await requestAxios(`cidades?estado=${uidEstado}`);
  return { error, result: data };
};

const alterarSituacao = async (uidUser) => {
  const { error, data = {} } = await requestAxios('e/alterar-situacao', {
    method: Method.POST,
    data: {
      uid_passageiro: uidUser,
    },
  });

  return { error, result: data.result };
};

const alterarSenha = async (uidUser, password) => {
  const { error, data = {} } = await requestAxios('e/alterar-senha', {
    method: Method.POST,
    data: {
      uid_passageiro: uidUser,
      senha: password,
    },
  });

  return { error, result: data.result };
};

const faturas = async () => {
  const { error, data = {} } = await requestAxios('e/faturas');

  return { error, result: data.result };
};

const alterarStatusFatura = async (uidInvoice, status) => {
  const { error, data = {} } = await requestAxios('e/status-fatura', {
    method: Method.POST,
    data: {
      uid: uidInvoice,
      status,
    },
  });

  return { error, result: data.result };
};

const emailsFatura = async () => {
  const { error, data = {} } = await requestAxios('e/emails-fatura');

  return { error, result: data.result };
};

const editarEmailFatura = async (uidEmailFatura) => {
  const { error, data = {} } = await requestAxios('e/editar-email-fatura', {
    method: Method.POST,
    data: {
      uid: uidEmailFatura,
    },
  });

  return { error, result: data.result };
};

const removerEmailFatura = async (uidEmailFatura) => {
  const { error, data = {} } = await requestAxios('e/remover-email-fatura', {
    method: Method.POST,
    data: {
      uid: uidEmailFatura,
    },
  });

  return { error, result: data.result };
};

const salvarEmailFatura = async (uid, name, email, description, defaultAlterado, defaultAnterior, uidEmpresa) => {
  const { error, data = {} } = await requestAxios('e/salvar-email-fatura', {
    method: Method.POST,
    data: {
      uid,
      nome: name,
      email,
      observacao: description,
      default: defaultAlterado,
      default_anterior: defaultAnterior,
      uid_empresa: uidEmpresa,
    },
  });

  return { error, result: data.result };
};

const corridasCanceladas = async (page = 1) => {
  const { error, data = {} } = await requestAxios(`e/corridas-canceladas?page=${page}`);

  return { error, result: data.result };
};

const corridasIniciadas = async (lat, lng, page = 1) => {
  const { error, data = {} } = await requestAxios(`e/corridas-iniciadas?cidade_lat=${lat}&cidade_lng=${lng}&page=${page}`);

  return { error, result: data.result };
};

const historico = async () => {
  const { error, data = {} } = await requestAxios('historico');

  return { error, result: data.result };
};

const canMakePhoneCall = async () => {
  const { error, data = {} } = await requestAxios('phone-call/can-make-phone-call');

  return { error, result: data.result };
};

const makePhoneCall = async (uidTrip, userPhoneNumber) => {
  const { error, data = {} } = await requestAxios('phone-call/make-phone-call', {
    method: Method.POST,
    data: {
      uid_corrida: uidTrip,
      user_phone: userPhoneNumber,
    },
  });

  return { error, result: data.result };
};

const listFavorites = async () => {
  const { error, data = {} } = await requestAxios('motorista-favorito/list');

  return { error, result: data.result };
};

const addFavorite = async (uidDriver) => {
  const { error, data = {} } = await requestAxios('motorista-favorito/add', {
    method: Method.POST,
    data: {
      uid_motorista: uidDriver,
    },
  });

  return { error, result: data.result };
};

const removeFavorite = async (uidDriver) => {
  const { error, data = {} } = await requestAxios('motorista-favorito/remove', {
    method: Method.POST,
    data: {
      uid_motorista: uidDriver,
    },
  });

  return { error, result: data.result };
};

const perfil = async () => {
  const { error, data = {} } = await requestAxios('perfil');

  return { error, result: data.result };
};

const editarPerfil = async (picture, firstName, lastName) => {
  const { error, data = {} } = await requestAxios('e/editar-perfil', {
    method: Method.POST,
    data: {
      foto: picture,
      nome: firstName,
      sobrenome: lastName,
    },
  });

  return { error, result: data.result };
};

const mensagemSuporte = async (subject, message) => {
  const { error, data = {} } = await requestAxios('mensagem-suporte', {
    method: Method.POST,
    data: {
      assunto: subject,
      mensagem: message,
    },
  });

  return { error, result: data.result };
};

const termosDeUso = () => (
  requestAxios('termosDeUso')
);

const buscaServicoEspecializado = async (uid) => {
  const { error, data = {} } = await requestAxios(`servicos-especializados/${uid}`);
  return { error, result: data.descricao };
};

const buscaServicoEmpresa = async (uid) => {
  const { error, data = {} } = await requestAxios(`servico-especializado-empresa/${uid}`);
  return { error, result: data.servico };
};

export default {
  login,
  termosDeUso,
  passwordRecovery,
  checkAuth,
  setFcmId,
  permissoes,
  reverseGeocode,
  places,
  estimativa,
  preco,
  solicitar,
  callDriver,
  dismiss,
  cancelarSolicitacao,
  configuracao,
  salvarConfiguracao,
  metodoPagamento,
  salvarMetodoPagamento,
  criarUsuario,
  listarUsuarios,
  listarPaises,
  listarEstados,
  listarCidades,
  alterarSituacao,
  alterarSenha,
  faturas,
  alterarStatusFatura,
  emailsFatura,
  editarEmailFatura,
  removerEmailFatura,
  salvarEmailFatura,
  corridasIniciadas,
  corridasCanceladas,
  historico,
  canMakePhoneCall,
  listFavorites,
  addFavorite,
  removeFavorite,
  perfil,
  editarPerfil,
  makePhoneCall,
  mensagemSuporte,
  listarEmpresas,
  buscaServicoEspecializado,
  getUidCidade,
  buscaServicoEmpresa,
};
