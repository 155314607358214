import Vue from 'vue';
import Vuex from 'vuex';
import uniqid from 'uniqid';
import _ from 'lodash';
import constants from '../constants';

const { AlertTypes } = constants;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: null,
    permissions: {},
    isAuthenticated: false,
    displayNavigationMenu: false,
    showNavigationMenu: false,
    showTripInfoMenu: false,
    showGeolocationMessage: false,
    currentPosition: { lat: -30.0277, lng: -51.2287 },
    geolocationWatchId: null,
    mapCenter: null,
    settings: {},
    origin: null,
    destination: null,
    lastTripUpdated: null,
    currentTripRequest: null,
    trips: null,
    blockRequest: false,
    mapDragging: false,
    alerts: [],
    map: null,
    markers: [],
  },
  getters: {
    getTripByUid: (state) => (uid) => (
      (state.trips || []).filter((value) => (value.uid === uid))[0]
    ),
  },
  mutations: {
    setUserData(state, data) {
      state.userData = data;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setIsAuthenticated(state, authenticated) {
      state.isAuthenticated = authenticated;
    },
    setDisplayNavigationMenu(state, display) {
      state.displayNavigationMenu = display;
    },
    setShowNavigationMenu(state, show) {
      state.showNavigationMenu = show;
    },
    setShowTripInfoMenu(state, show) {
      state.showTripInfoMenu = show;
    },
    setShowGeolocationMessage(state, show) {
      state.showGeolocationMessage = show;
    },
    setCurrentPosition(state, position) {
      state.currentPosition = position;
    },
    setGeolocationWatchId(state, id) {
      state.geolocationWatchId = id;
    },
    setMapCenter(state, center) {
      state.mapCenter = center;
    },
    setMap(state, map) {
      state.map = map;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setOrigin(state, origin) {
      state.origin = origin;
    },
    setDestination(state, destination) {
      state.destination = destination;
    },
    setMapDragging(state, dragging) {
      state.dragging = dragging;
    },
    setMarkers(state, markers) {
      state.markers = markers;
    },
    showInfoAlert(state, message) {
      state.alerts.push({ message, id: uniqid(), type: AlertTypes.INFO });
    },
    showSuccessAlert(state, message) {
      state.alerts.push({ message, id: uniqid(), type: AlertTypes.SUCCESS });
    },
    showWarningAlert(state, message) {
      state.alerts.push({ message, id: uniqid(), type: AlertTypes.WARNING });
    },
    showErrorAlert(state, error) {
      let errorMessage = 'Ocorreu um erro';

      if (typeof error === 'string') {
        errorMessage = error;
      } else if ((error || {}).msg) {
        errorMessage = error.msg;
      }

      state.alerts.push({ message: errorMessage, id: uniqid(), type: AlertTypes.ERROR });
    },
    removeAlert(state, id) {
      const index = state.alerts.findIndex((element) => (element.id === id));

      state.alerts.splice(index, 1);
    },
    updateTrip(state, trip) {
      const trips = state.trips || [];
      const index = trips.findIndex((value) => (value.uid === trip.uid));

      if (index !== -1) {
        Vue.set(state.trips, index, { ...state.trips[index], ...trip });
      }

      state.lastTripUpdated = trip;
    },
    setCurrentTripRequest(state, trip) {
      state.currentTripRequest = trip;
    },
    setTrip(state, trip) {
      state.trips = [trip, ...state.trips || []];
    },
    setTrips(state, trips) {
      state.trips = _.unionBy(trips, state.trips || [], 'uid');
    },
    setBlockRequest(state, block) {
      state.blockRequest = block;
    },
    removeTrip(state, trip) {
      state.trips = (state.trips || []).filter((value) => (value.uid !== trip.uid));
    },
  },
  actions: {
  },
  modules: {
  },
});
