<template>
  <div id="trip-info-menu">
    <div id="menu-content">
      <component :is="selectedTab"></component>
    </div>
    <div id="tabs">
      <div class="tab-item current-trips" @click="setTab(0)">
        <svg v-if="selectedTab === 'RequestedTripsTab'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,48C141.13,48,48,141.13,48,256s93.13,208,208,208,208-93.13,208-208S370.87,48,256,48Zm96,240H256a16,16,0,0,1-16-16V128a16,16,0,0,1,32,0V256h80a16,16,0,0,1,0,32Z" style="fill:#fff"/></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,64C150,64,64,150,64,256s86,192,192,192,192-86,192-192S362,64,256,64Z" style="fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:32px"/><polyline points="256 128 256 272 352 272" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
      </div>
      <div class="tab-item" @click="setTab(1)">
        <svg v-if="selectedTab === 'StartedTripsTab'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM168,350a24,24,0,1,1,24-24A24,24,0,0,1,168,350Zm0-71a24,24,0,1,1,24-24A24,24,0,0,1,168,279Zm0-73a24,24,0,1,1,24-24A24,24,0,0,1,168,206ZM352,341H224a16,16,0,0,1,0-32H352a16,16,0,0,1,0,32Zm0-71H224a16,16,0,0,1,0-32H352a16,16,0,0,1,0,32Zm0-72H224a16,16,0,0,1,0-32H352a16,16,0,0,1,0,32Z" style="fill:#fff"/></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><line x1="224" y1="184" x2="352" y2="184" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="224" y1="256" x2="352" y2="256" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="224" y1="327" x2="352" y2="327" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><path d="M448,258c0-106-86-192-192-192S64,152,64,258s86,192,192,192S448,364,448,258Z" style="fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:32px"/><circle cx="168" cy="184" r="8" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><circle cx="168" cy="257" r="8" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><circle cx="168" cy="328" r="8" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
      </div>
      <div class="tab-item" @click="setTab(2)">
        <svg v-if="selectedTab === 'CanceledTripsTab'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm75.31,260.69a16,16,0,1,1-22.62,22.62L256,278.63l-52.69,52.68a16,16,0,0,1-22.62-22.62L233.37,256l-52.68-52.69a16,16,0,0,1,22.62-22.62L256,233.37l52.69-52.68a16,16,0,0,1,22.62,22.62L278.63,256Z" style="fill:#fff"/></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z" style="fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:32px"/><line x1="320" y1="320" x2="192" y2="192" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="192" y1="320" x2="320" y2="192" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
import RequestedTripsTab from './RequestedTripsTab.vue';
import StartedTripsTab from './StartedTripsTab.vue';
import CanceledTripsTab from './CanceledTripsTab.vue';

export default {
  components: { RequestedTripsTab, StartedTripsTab, CanceledTripsTab },
  data() {
    return {
      selectedTab: '',
      tabs: ['Requested', 'Started', 'Canceled'],
    };
  },
  methods: {
    setTab(index) {
      this.selectedTab = `${this.tabs[index]}TripsTab`;
    },
  },
  created() {
    this.setTab(0);
  },
};
</script>

<style scoped lang="scss">
  #trip-info-menu {
    position: absolute;
    width: 100%;
    max-width: 980px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    background-color: #eee;
    box-shadow: 0 0 15px -5px #444;
  }

  #menu-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 44px;
  }

  #tabs {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: #25A58C;

    .tab-item {
      cursor: pointer;
      display: flex;
      padding: 2px 0;
      flex-grow: 1;

      svg {
        width: 40px;
        margin: auto;
        display: block;
      }
    }
  }
</style>
