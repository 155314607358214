<template>
  <div id="canceled-trips-tab">
    <paginator-view @paginate="loadMore">
      <tab-view-list :list="content.data" emptyListMessage="Nenhuma corrida cancelada"></tab-view-list>
    </paginator-view>
  </div>
</template>

<script>
import PaginatorView from './PaginatorView.vue';
import TabViewList from './TabViewList.vue';
import garupa from '../api/garupa';

export default {
  components: { PaginatorView, TabViewList },
  data() {
    return {
      content: {},
    };
  },
  methods: {
    async loadMore() {
      if (this.content.current_page + 1 <= this.content.last_page) {
        const { error, result } = await garupa.corridasCanceladas(
          this.content.current_page + 1,
        );

        if (result) {
          const currentList = this.content.data;

          this.content = result;

          this.content.data = [...currentList, ...result.data];
        } else {
          this.$store.commit('showErrorAlert', error);
        }
      }
    },
  },
  async created() {
    const { error, result } = await garupa.corridasCanceladas();

    if (result) {
      this.content = result;
    } else {
      this.$store.commit('showErrorAlert', error);
    }
  },
};
</script>

<style scoped lang="scss">
  #canceled-trips-tab {
    height: 100%;
  }
</style>
