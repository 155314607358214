<template>
  <button :type="type" :disabled="isDisabled">
    <slot></slot>
    <div class="spinner" v-if="isDisabled">
      <svg xmlns="http://www.w3.org/2000/svg" style="margin: auto; background: none; display: block; shape-rendering: auto;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" :stroke="spinnerColor" stroke-width="10" r="40" stroke-dasharray="188.49555921538757 64.83185307179586">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.5847953216374269s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    isDisabled: Boolean,
    type: {
      type: String,
      default: 'button',
    },
    spinnerColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>

<style scoped lang="scss">
  button {
    position: relative;

    .spinner {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate(0px, -50%);

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
</style>
