<template>
  <div id="suporte" class="center">
    <form @submit.prevent="sendMessage">
      <div class="form-field">
        <label for="subject">Assunto</label>
        <input id="subject" class="subject" type="text" v-model="subject" maxlength="60" :disabled="isSending">
        <span class="error" v-if="submitted && !subject.trim()">Campo obrigatório</span>
      </div>
      <div class="form-field">
        <label for="message">Mensagem</label>
        <textarea id="message" class="message" v-model="message" rows="10" maxlength="10000" :disabled="isSending"></textarea>
        <span class="error" v-if="submitted && !message.trim()">Campo obrigatório</span>
      </div>
      <ajax-button class="button button-primary" type="submit" :is-disabled="isSending">Enviar</ajax-button>
    </form>
  </div>
</template>

<script>
import garupa from '../api/garupa';
import AjaxButton from '../components/AjaxButton.vue';

export default {
  components: { AjaxButton },
  data() {
    return {
      subject: '',
      message: '',
      isSending: false,
      submitted: false,
    };
  },
  methods: {
    async sendMessage() {
      this.submitted = true;

      if (!this.subject.trim() || !this.message.trim()) {
        return;
      }

      this.isSending = true;

      const { error, result } = await garupa.mensagemSuporte(this.subject, this.message);

      this.isSending = false;

      if (result) {
        this.subject = '';
        this.message = '';
        this.submitted = false;

        this.$store.commit('showSuccessAlert', 'Mensagem enviada com sucesso');
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  form {
    display: flex;
    flex-direction: column;

    .message {
      border-radius: 25px;
    }

    .button {
      max-width: 360px;
      margin: auto;
      width: 100%;
    }
  }
</style>
