<template>
  <div id="chamar">
    <router-view></router-view>
    <leaflet-map></leaflet-map>
  </div>
</template>

<script>
import LeafletMap from '../components/LeafletMap.vue';

export default {
  components: { LeafletMap },
};
</script>

<style scoped lang="scss">
  #chamar {
    max-width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
  }
</style>
