// eslint-disable-next-line import/no-extraneous-dependencies
import * as firebase from 'firebase/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'firebase/database';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const instance = firebase.initializeApp(firebaseConfig);

const geo = firebase.initializeApp({
  ...firebaseConfig,
  databaseURL: process.env.VUE_APP_FIREBASE_GEO_DATABASE_URL,
}, 'geo');

const talk = firebase.initializeApp({
  ...firebaseConfig,
  databaseURL: process.env.VUE_APP_FIREBASE_TALK_DATABASE_URL,
}, 'talk');

export default {
  instance,
  geo,
  talk,
};
