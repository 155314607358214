<template>
  <div id="navbar">
    <button id="menu" type="button" @click="openNavigationMenu" :style="{ 'visibility': showNavigationMenuButton ? 'visible' : 'hidden' }">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><line x1="80" y1="160" x2="432" y2="160"/><line x1="80" y1="256" x2="432" y2="256"/><line x1="80" y1="352" x2="432" y2="352"/></svg>
    </button>
    <div id="title">
      <slot></slot>
    </div>
    <button id="info" type="button" @click="openInfoMenu" :style="{ 'visibility': showTripInfoMenuButton ? 'visible' : 'hidden' }">
      <svg v-if="showTripInfoMenu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,56C145.72,56,56,145.72,56,256s89.72,200,200,200,200-89.72,200-200S366.28,56,256,56Zm0,82a26,26,0,1,1-26,26A26,26,0,0,1,256,138Zm48,226H216a16,16,0,0,1,0-32h28V244H228a16,16,0,0,1,0-32h32a16,16,0,0,1,16,16V332h28a16,16,0,0,1,0,32Z" style="fill:#fff"/></svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:32px"/><polyline points="220 220 252 220 252 336" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="208" y1="340" x2="296" y2="340" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path d='M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z' style="fill:#fff"/></svg>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    showNavigationMenuButton: {
      type: Boolean,
    },
    showTripInfoMenuButton: {
      type: Boolean,
    },
  },
  computed: mapState(['showNavigationMenu', 'showTripInfoMenu']),
  methods: {
    openNavigationMenu() {
      this.$store.commit('setDisplayNavigationMenu', true);
      this.$store.commit('setShowTripInfoMenu', false);
      this.$store.commit('setShowNavigationMenu', !this.showNavigationMenu);
    },
    openInfoMenu() {
      this.$store.commit('setShowTripInfoMenu', !this.showTripInfoMenu);
    },
  },
};
</script>

<style scoped lang="scss">
  #navbar {
    background-color: #25a58c;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: 44px;
    display: flex;
  }

  #title {
    line-height: 44px;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0 10px;
    cursor: pointer;
    outline: 0;
    margin: 0;
  }

  svg {
    display: block;
    width: 35px;

    line {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 32px;
    }

    polyline {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 48px;
    }
  }
</style>
