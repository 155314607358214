<template>
  <div id="tab-view-item" class="list-item">
    <div id="driver-info" v-if="item.motorista">
      <img :src="item.motorista.foto">
      <div class="info">
        <div class="name">
          {{ item.motorista.nome }}
        </div>
        <div class="vehicle">
          {{ item.motorista.marca || '' }} {{ item.motorista.modelo || '' }} {{ item.motorista.cor || '' }} {{ item.motorista.placa || '' }}
        </div>
      </div>
    </div>
    <div id="trip-info">
      <div class="status" v-if="item.status">
        <span>Status:</span> {{ item.status | formatTripStatus }}
      </div>
      <div class="origin" v-if="item.endereco_partida">
        <span>Origem:</span> {{ item.endereco_partida }}
      </div>
      <div class="destination" v-if="item.endereco_destino">
        <span>Destino:</span> {{ item.endereco_destino }}
      </div>
      <div class="price" v-if="item.valor_estimado">
        <span>Valor estimado:</span> {{ item.valor_estimado | formatPrice }}
      </div>
      <div class="type" v-if="item.tipo">
        <span>Tipo:</span> {{ item.tipo | formatServiceType }}
      </div>
    </div>
    <div id="actions">
      <a class="acomp button button-primary" :disabled="disableAcompanharCorrida(item.status)" :href="disableAcompanharCorrida(item.status) ? '#' : urlAcompanharCorrida + item.uid" target="_blank">Acompanhar corrida</a>
      <button v-if="item.status == 35" class="historic" title="Histórico de Corridas" @click="closeInfoMenu()">
        <router-link to="/historico">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M256,160c16-63.16,76.43-95.41,208-96a15.94,15.94,0,0,1,16,16V368a16,16,0,0,1-16,16c-128,0-177.45,25.81-208,64-30.37-38-80-64-208-64-9.88,0-16-8.05-16-17.93V80A15.94,15.94,0,0,1,48,64C179.57,64.59,240,96.84,256,160Z" />
            <line x1="256" y1="160" x2="256" y2="448" />
          </svg>
        </router-link>
      </button>
    </div>
    <div id="actions" v-if="item.status < tripStatus.ANDAMENTO">
      <ajax-button class="cancel button button-primary" :is-disabled="isCancelingTrip" @click.native="cancelTrip(item)">Cancelar Garupa</ajax-button>
      <button class="chat" @click="openChatModal(item)" v-if="item.status === tripStatus.MOTORISTA_DESLOCAMENTO">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M408 48H104a72.08 72.08 0 00-72 72v192a72.08 72.08 0 0072 72h24v64a16 16 0 0026.25 12.29L245.74 384H408a72.08 72.08 0 0072-72V120a72.08 72.08 0 00-72-72zM160 248a32 32 0 1132-32 32 32 0 01-32 32zm96 0a32 32 0 1132-32 32 32 0 01-32 32zm96 0a32 32 0 1132-32 32 32 0 01-32 32z"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import garupa from '../api/garupa';
import constants from '../constants';
import ConfirmPhoneNumberModal from './ConfirmPhoneNumberModal.vue';
import ChatModal from './ChatModal.vue';
import AjaxButton from './AjaxButton.vue';

const { TripStatus } = constants;

export default {
  components: { AjaxButton },
  props: {
    item: Object,
    showNavigationMenuButton: {
      type: Boolean,
    },
    showTripInfoMenuButton: {
      type: Boolean,
    },
    itemlist: Boolean,
  },
  data() {
    return {
      isCancelingTrip: false,
      phoneNumber: '',
      urlAcompanharCorrida: process.env.VUE_APP_CORRIDA_URL,
    };
  },
  computed: mapState(['showNavigationMenu', 'showTripInfoMenu']),
  // eslint-disable-next-line no-dupe-keys
  computed: {
    tripStatus() {
      return TripStatus;
    },
  },
  methods: {
    async cancelTrip(trip) {
      this.isCancelingTrip = true;

      const { error, result } = await garupa.cancelarSolicitacao(trip.uid);

      if (result) {
        garupa.dismiss(trip.uid);

        if (trip.status === TripStatus.SOLICITANDO) {
          this.$store.commit('showSuccessAlert', 'Garupa cancelada');
          this.$store.commit('removeTrip', trip);
          localStorage.removeItem('currentTripRequest');
        }
      } else {
        this.isCancelingTrip = false;

        this.$store.commit('showErrorAlert', error);
      }
    },
    openConfirmPhoneModal(trip) {
      this.$modal.show(ConfirmPhoneNumberModal, { trip }, {
        width: '500px',
        height: 'auto',
      });
    },
    openChatModal(trip) {
      this.$modal.show(ChatModal, { trip }, {
        width: '800px',
        height: '100%',
      });
    },
    disableAcompanharCorrida(status) {
      return status < TripStatus.ANDAMENTO || status > TripStatus.FINALIZADA;
    },
    closeInfoMenu() {
      this.$store.commit('setShowTripInfoMenu', false);
    },
  },
};
</script>

<style scope lang="scss">
#tab-view-item {
    margin-bottom: 15px;
  }

  #driver-info {
    display: flex;

    img {
      display: block;
      border-radius: 2px;
      width: 50px;
      height: 50px;
    }

    .info {
      margin-left: 10px;

      .name {

      }

      .vehicle {
        color: #777;
      }
    }
  }

  #trip-info {
    margin-top: 10px;

    .status,
    .destination,
    .origin,
    .price,
    .type {
      span {
        font-weight: bold;
      }
    }

    .destination {
      color: #db4d6c;
    }
    .origin {
      color: #1c7566;
    }
  }

  #actions {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    .cancel {
      border-radius: 2px;
      flex-grow: 3;
    }

    .acomp {
      border-radius: 2px;
      flex-grow: 3;
    }

    .historic,
    .chat {
      border: none;
      background-color: transparent;
      outline: 0;
      margin: 0;
      cursor: pointer;
      flex-grow: 1;

      svg {
        width: 35px;
        fill: #333;
      }
    }
  }
  a[disabled="disabled"] {
    pointer-events: none;
    cursor: default;
    background-color: #ddd !important;
    color: #b3b3b3 !important;
  }
</style>
