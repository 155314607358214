<template>
  <div id="perfil" class="center">
    <form @submit.prevent="submit">
      <div class="picture">
        <label for="file">
          <div class="image-wrapper">
            <img :src="profile.picture" :class="{ 'loaded': profilePictureLoaded }" @load="onProfilePictureLoad">
          </div>
          <span>Escolha uma foto</span>
          <input id="file" type="file" ref="file" accept=".jpg,.jpeg,.png" @change="uploadImage" :disabled="isLoading">
        </label>
      </div>
      <div class="form-field">
        <label for="first-name">Nome</label>
        <input id="first-name" type="text" v-model="profile.firstName" :disabled="isLoading" maxlength="50">
        <span class="error" v-if="submitted && !profile.firstName.trim()">Campo obrigatório</span>
      </div>
      <div class="form-field">
        <label for="last-name">Sobrenome</label>
        <input id="last-name" type="text" v-model="profile.lastName" :disabled="isLoading" maxlength="50">
        <span class="error" v-if="submitted && !profile.lastName.trim()">Campo obrigatório</span>
      </div>
      <div class="form-field">
        <label>Celular</label>
        <input class="mobile-number" type="tel" :value="profile.mobileNumber | formatPhoneNumber" disabled>
      </div>
      <ajax-button class="btn-save button button-primary" type="submit" :is-disabled="isLoading">Salvar</ajax-button>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import garupa from '../api/garupa';
import AjaxButton from '../components/AjaxButton.vue';
import store from '../store/index';

export default {
  components: { AjaxButton },
  data() {
    return {
      profile: {
        picture: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
      profilePictureLoaded: false,
      submitted: false,
      isLoading: false,
    };
  },
  methods: {
    async submit() {
      if (!this.profile.firstName.trim() || !this.profile.lastName.trim()) {
        return;
      }

      this.submitted = true;
      this.isLoading = true;
      const { error, result } = await garupa.editarPerfil(
        this.profile.picture,
        this.profile.firstName,
        this.profile.lastName,
      );

      this.isLoading = false;

      if (result) {
        store.commit('showSuccessAlert', 'Perfil atualizado com sucesso');
      } else if (error && error.errors) {
        _.forEach(error.errors, (value) => {
          store.commit('showWarningAlert', value[0]);
        });
      } else {
        store.commit('showErrorAlert', error);
      }
    },
    uploadImage(event) {
      const { files } = event.target;

      if (files && files.length) {
        const file = files[0];
        const reader = new FileReader();

        if (file.size > 1024 * 1024 * 2) {
          store.commit('showErrorAlert', 'Somente imagens com menos de 2mb podem ser enviadas');
          this.$refs.file.value = '';

          return;
        }

        reader.onload = (e) => {
          this.profile.picture = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    },
    onProfilePictureLoad() {
      this.profilePictureLoaded = true;
    },
  },
  async created() {
    this.isLoading = true;

    const { error, result } = await garupa.perfil();

    this.isLoading = false;

    if (result) {
      this.profile = {
        picture: result.foto,
        firstName: result.nome,
        lastName: result.sobrenome,
        mobileNumber: result.celular,
      };

      this.profileLoaded = true;
    } else {
      store.commit('showErrorAlert', error);
    }
  },
};
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;

  .picture {
    margin-bottom: 15px;

    label {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .image-wrapper {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        align-self: center;
        overflow: hidden;
        background-color: #eee;

        img {
          display: block;
          opacity: 0;
          width: 100%;
          height: 100%;

          &.loaded {
            opacity: 1;
          }
        }
      }

      span {
        display: block;
        text-align: center;
        color: #333;
        line-height: 22px;
        margin-top: 10px;
      }

      input[type=file] {
        display: none;
      }
    }

  }

  .mobile-number::-webkit-outer-spin-button,
  .mobile-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .button {
    width: 100%;
    max-width: 360px;
    margin: auto;
  }
}
</style>
