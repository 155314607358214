<template>
  <div id="destino">
    <div id="destination-box" :class="{ 'fill': !isPinMode }">
      <div id="content">
        <input type="text" class="origin-text-field input-text" v-model="origin.address" placeholder="Sua localização atual" @keyup="lazySearch" @focus="onOriginFocus" maxlength="100">
        <input type="text" class="input-text" v-model="destination.address" placeholder="Onde você quer ir?" @keyup="lazySearch" @focus="onDestinationFocus" maxlength="100">
        <div v-show="!isPinMode">
          <div id="pin">
            <span :class="[isOriginSearch ? 'origin' : 'destination']" @click="isPinMode = true">
              {{ isOriginSearch ? 'MARCAR PONTO DE PARTIDA NO MAPA' : 'MARCAR DESTINO NO MAPA' }}
            </span>
          </div>
          <div id="places">
            <ul v-if="!isSearchingPlace">
              <li v-for="place in places" :key="place.placeID" @click="selectAddress(place)">{{ place.description }}</li>
            </ul>
            <ul v-if="isSearchingPlace">
              <li>Pesquisando endereços...</li>
            </ul>
            <ul v-if="placeNotFound">
              <li>Nenhum resultado encontrato</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="marker" :class="[isOriginSearch ? 'origin' : 'destination']">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336,96a80,80,0,1,0-96,78.39V457.56a32.09,32.09,0,0,0,2.49,12.38l10.07,24a3.92,3.92,0,0,0,6.88,0l10.07-24A32.09,32.09,0,0,0,272,457.56V174.39A80.13,80.13,0,0,0,336,96Zm-56,0a24,24,0,1,1,24-24A24,24,0,0,1,280,96Z"/></svg>
    </div>
    <ajax-button id="btn-price" class="button button-primary" @click.native="nextStep" :is-disabled="isSearchingAddress">Ver Preço</ajax-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import store from '../store/index';
import garupa from '../api/garupa';
import AjaxButton from '../components/AjaxButton.vue';

export default {
  components: { AjaxButton },
  data() {
    return {
      origin: {
        address: '',
      },
      destination: {
        address: '',
      },
      places: [],
      isPinMode: true,
      isOriginSearch: false,
      canReverseGeocode: true,
      isSearchingAddress: false,
      isSearchingPlace: false,
      placeNotFound: false,
    };
  },
  computed: mapState(['map', 'mapCenter', 'currentPosition']),
  watch: {
    async mapCenter(newPosition) {
      if (this.isPinMode && this.canReverseGeocode) {
        const { lat, lng } = newPosition;
        const address = await this.getAddress(lat, lng);

        if (this.isOriginSearch) {
          this.setOrigin(lat, lng, address);
        } else {
          this.setDestination(lat, lng, address);
        }
      }

      this.canReverseGeocode = true;
    },
  },
  methods: {
    formatAddress(address = {}) {
      const { rua, numero, cidade, uf } = address;

      return `${rua}, ${numero}. ${cidade}/${uf}`;
    },
    async getAddress(lat, lng) {
      this.isSearchingAddress = true;

      const { error, result } = await garupa.reverseGeocode(lat, lng);

      this.isSearchingAddress = false;

      if (result) {
        return typeof result === 'string' ? result : this.formatAddress(result);
      }

      this.$store.commit('showErrorAlert', error);

      return null;
    },
    async search() {
      let { address } = this.destination;

      if (this.isOriginSearch) {
        address = this.origin.address;
      }

      this.isSearchingPlace = true;
      this.placeNotFound = false;

      const { error, result } = await garupa.places(
        this.currentPosition.lat,
        this.currentPosition.lng,
        address,
      );

      this.isSearchingPlace = false;

      if (result) {
        this.places = result;

        if (_.isEmpty(result)) {
          this.placeNotFound = true;
        }
      } else {
        this.$store.commit('showErrorAlert', error);
      }
    },
    selectAddress(place) {
      const address = place.description;
      const { lat, lon: lng } = place.coords;

      this.canReverseGeocode = false;
      this.isPinMode = true;
      this.map.setView([lat, lng]);

      if (this.isOriginSearch) {
        this.setOrigin(lat, lng, address);
      } else {
        this.setDestination(lat, lng, address);
      }
    },
    setOrigin(lat, lng, address) {
      this.origin = { lat, lng, address };

      this.$store.commit('setOrigin', this.origin);
    },
    setDestination(lat, lng, address) {
      this.destination = { lat, lng, address };

      this.$store.commit('setDestination', this.destination);
    },
    onOriginFocus() {
      this.isPinMode = false;
      this.isOriginSearch = true;

      if (this.origin && this.origin.lat && this.origin.lng) {
        const { lat, lng } = this.origin;
        this.map.setView([lat, lng]);
      } else {
        const { lat, lng } = this.currentPosition;
        this.map.setView([lat, lng]);
      }
    },
    onDestinationFocus() {
      this.isPinMode = false;
      this.isOriginSearch = false;
      if (this.destination.address) {
        const { lat, lng } = this.destination;
        this.map.setView([lat, lng]);
      }
    },
    async nextStep() {
      if (this.validaOrigemDestino()) return;
      if (!this.origin || (!this.origin.lat && !this.origin.lng)) {
        const { lat, lng } = this.currentPosition;
        const address = await this.getAddress(lat, lng);

        this.setOrigin(lat, lng, address);
      }

      this.$router.push('/chamar/estimativa');
    },
    validaOrigemDestino() {
      if (this.origin.lat === this.destination.lat && this.origin.lng === this.destination.lng) {
        this.$store.commit('showErrorAlert', 'Os endereços de origem e destino dever ser diferentes');
        return true;
      }
      return false;
    },
  },
  async created() {
    this.lazySearch = _.debounce(this.search, 1000);

    const { lat, lng } = this.currentPosition;
    const address = await this.getAddress(lat, lng);
    this.setOrigin(lat, lng, address);
  },
  beforeRouteEnter(to, from, next) {
    const { currentTripRequest, blockRequest } = store.state;

    if (currentTripRequest && !blockRequest) {
      next('/chamar/chamar-motorista');
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
  #destination-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 0 0 2px 2px;
    z-index: 20;
    transform: translate(-50%, 0);
    box-shadow: 0 1px 8px -5px #333;
    top: 0;
    left: 50%;

    &.fill {
      height: 100%;
    }
  }

  #content {
    max-width: 980px;
    width: 100%;
    margin: 0 auto;

    .origin-text-field {
      margin-bottom: 12px;
    }
  }

  #pin {
    margin-top: 12px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;

    span {
      display: block;
      line-height: 18px;
      font-size: 16px;
      padding: 20px 15px;
      box-sizing: border-box;
      cursor: pointer;

      &.origin {
        color: #25A58C;
      }

      &.destination {
        color: #db4d6c;
      }
    }
  }

  #places {
    ul {
      li {
        line-height: 22px;
        font-size: 15px;
        padding: 20px 15px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
        color: #333;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  #marker {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 15;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    &.origin {
      svg {
        fill: #25A58C;
      }
    }

    &.destination {
      svg {
        fill: #db4d6c;
      }
    }

    svg {
      width: 70px;
      position: relative;
      bottom: 30px;
      display: block;
    }
  }

  #btn-price {
    position: absolute;
    bottom: 35px;
    width: 100%;
    max-width: 300px;
    left: 50%;
    z-index: 15;
    transform: translate(-50%, 0);
    box-shadow: 0 0 8px -5px #333;
  }
</style>
