<template>
  <div id="requested-trips-tab">
    <tab-view-list :list="trips" emptyListMessage="Nenhuma corrida solicitada"></tab-view-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TabViewList from './TabViewList.vue';

export default {
  components: { TabViewList },
  computed: mapState(['trips']),
};
</script>

<style scoped lang="scss">
  #requested-trips-tab {
    height: 100%;
  }
</style>
